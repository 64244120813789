/*==== manufacture ====*/
.content-manufacture-block {
	border-top: 1px solid $gray-lighter;
	padding: 30px 0px 16px 0px;
	overflow: hidden;
	ul {
		text-align: center;
		li {
			display: inline-block;
			text-align: center;
			padding: 10px 50px;
			@media (max-width: 1200px) {
				padding: 10px 25px;
			}
			img {
				width: auto;
			}
		}
	}
}

/*==== Information =====*/
#footer {
	background: $brand-secondary;
	padding-top: 36px;
	@include fontStyle--5();
	.row {
		>div {
			@media (max-width: 767px) {
				max-width: 100%;
				flex: 100%;
			}
		}
	}
	img {
		width: auto; 
		@media (max-width: 320px) {
			width: 100%;
		}
	} 
	.footer-container {
		.row {
			margin: 0px -15px;
		}
		a {
			color: #a8a8a8;
		}
	}
	.text-address {
	    margin: 42px 0px 0px 0px;
	    color: #a8a8a8;
	    font-size: $base-font-size;
	    >div {
	    	padding-bottom: 21px;
	    }
	    p {
	    	margin: 0px;
	    	padding-bottom: 21px;
	    	&:last-child {
	    		padding-bottom: 0px;
	    	}
	    }
	}
	.social-footer {
		li {
			float: left;
			padding-right: 25px;
			padding-top: 5px;
			a {
				font-size: 0;
				color: $textColor-1;
				i {
					font-size: 24px;
				}
			}
		}
	}

	/*===== Our support ======*/
	.wrapper {
		padding-top: 15px;
		.h3 {
			@include fontStyle--4();
			color: #fff;
			font-weight: 500;
			margin-bottom: 45px;
			&.hidden-sm-down {
				@media (max-width: 767px) {
					display: none;
				}
			}
		}
		.title.hidden-md-up {
			display: none;
			@media (max-width: 767px) {
				display: block;
				cursor: pointer;
				margin-bottom: 15px;
				position: relative;
				.float-xs-right {
					@include icon-mobile($textColor-3);
				}
				&[aria-expanded="true"] {
					@include icon-mobile-active;
				}
			}
		}
		ul {
			@media (min-width: 768px) {
				display: block;
			}
			@media (max-width: 767px) {
				&.in {
					display: block;
				}
			}
			li {
				padding-bottom: 21px;
				a {
					font-size: inherit;
					font-family: inherit;
					text-transform: capitalize;
					padding: 0px;
					&:hover {
						color: $textColor-1;
					}
				}
			} 
		}
	}
	/*==== Newsletters =====*/
	.newsletter_block_left {
		h4 {
			padding-top: 15px;
			color: #fff;
			font-weight: 500;
			margin-bottom: 45px;
			@media (max-width: 767px){
				margin-bottom: 20px;
			}
		}
		input {
			background: #484848;
			text-indent: 5px;
			border: none !important;
			color: #fff;
		}
		.btn-default {
			margin-top: 22px;
			text-transform: uppercase;
			color: #a8a8a8;
			padding: 10px 18px;
			background: transparent;
			&:hover {
				color: #fff;
				background: $brand-primary;
			}
		}
		.notification-success {
			color: #4bac52;
		}
		.notification-error {
			color: #ed0404;
		}
	}
}

.footer-after {
	background: $brand-secondary;
	.footer_inner {
	    width: 100%;
	    border-top: 1px solid #2c2b2a;
	    padding: 18px 0px 15px 0px;
	    // margin: 0px 15px;
	    @media (max-width: 767px) {
			padding: 18px 15px 15px 15px;
	    }
	    .bc-bewaer-ft {
			float: right;
			@media (max-width: 545px) {
				float: left; 
			}
			img {
				width: auto;
				@media (max-width: 320px) {
				width: 100%;
			}
			}
		}
		.copy-right {
			@include fontStyle--5();
			font-weight: 100;
			color: #a8a8a8; 
			line-height: 35px;
			float: left;
			i {
				font-size: 14px;
				color: $copyright;
			}
		}
	}
}
#cookie-popup {
	background: rgba(19, 19, 19, 0.84);
    position: fixed;
    bottom: 0px;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    .text_content {
    	float: left;
    	color: #fff;
    	margin-top: 10px;
    }
	#cookie_block_popup {
		padding: 20px 10px;
		.button-container {
		    text-align: right;
		    .accept.btn-default {
				@extend .btn-primary; 
				width: auto;
				margin-right: 15px;
				@media (max-width: 767px) {
					width: 100%;
					margin-bottom: 15px;
				}
		    }
		}
	}
}