
.lang-rtl {
  * {
    direction: rtl !important;
  }
  main {
    text-align: right !important;
  }
}
body,
html {
  height: 100%;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-family: $theme-font;
  font-size: $base-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $gray-darker;
  line-height: 1.25em;
  background: $bgColor;
  @media (max-width: 767px) {
    &.menu-opened {
      overflow: hidden;
      #page {
        cursor: url(../img/close.png), pointer;
        position: relative;
        &:before {
          content: "";
          background: rgba(0,0,0,0.5);
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1000;
        }
      }
    }
  }
}
ul {
  list-style: none;
  padding-left: 0;
}
a {
  color: $textColor-2;
  outline: none;
  text-decoration: none;
  &:hover {
    color: $brand-primary;
    text-decoration: none;
  }
}
hr {
  border: none;
  margin: 0;
  padding: 0;
}
img {
  width: 100%;
}
.container {
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
  @media (max-width: 991px) {
      max-width: 980px;
  }
  @media (max-width: 767px) {
      max-width: 760px;
  }
}
.btn-unstyle {
  background: transparent;
  border: none;
  outline: none;
  position: relative;
}
header .row {
  margin: 0px -15px;
}

label ,.label {
  color: $gray-darker;
  font-size: $base-font-size;
}
select.form-control, 
input[type="text"]:not(.search_query),  
input[type="password"], 
input[type="email"]{
  outline: none;
  height: 40px;
  border: 1px solid $gray-lighter !important;
  font-family: $theme-font;
}
h1 {
  font-size: $font-size-h3;
  font-family: $theme-font;
}
h3 {
  @include fontStyle--3();
}
h2 {
  @include fontStyle--2();
}
h4 {
   @include fontStyle--4();
}
.h6, h6 {
   @include fontStyle--4();
   color: $textColor-2;
}
i, cite, em, var, address, dfn {
  font-style: normal;  
}
.zoomContainer {
  z-index: 10009;
} 


#scroll-to-top {
  float: right;
  position: fixed;
  bottom: 90px;
  right: 10px;
  font-size: 0;
  cursor: pointer;
  z-index: 99;
  transform: scale(1);
  transition: all 0.5s ease-in-out;
  &:before {
    content: "\f2fc";
    font-family: $font-icon;
    font-size: 24px;
    background: $brand-primary;
    padding: 10px 15px;
    color: #fff;
    transition: all 0.5s ease-in-out;

  }
  &:hover {
    &:before {
      background: $brand-primary;
    }
  }
}

.pagination {
  display: block;
  margin: 0;
  ul {
    text-align: center;
    position: relative;
    li {
      display: inline-block;
      border: 1px solid $gray-lighter;
      width: 40px;
      height: 40px;
      background: $bgColor;
      cursor: pointer;
      margin: 2px;
      span {
        line-height: 40px;
      }
      &.pagination_previous, &.pagination_next, &.disabled {
        font-size: 0;
        position: relative;
        i {
          line-height: 40px;
          width: 40px;
          height: 40px;
          text-align: center;
          font-family: $font-icon;
          font-size: 14px;
          font-weight: 700;
        }
        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
          &:hover {
            border: 1px solid $gray-lighter;
            background: $bgColor;
            color: #000000;
          }
        }
      }
      &.pagination_next,  &.disabled {
        i:before {
          content: '\f2ee';
        }
      }
      &.pagination_previous {
        i:before {
          content: '\f2ea';
        }
      }
      &.current, &:hover {
        border: 1px solid $brand-primary;
        background: $brand-primary;
        color: $textColor-3 ;
        a {
          color: $textColor-3;
        }
      }
    }
  }
}

.custom-checkbox {
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    margin-top: 3px;
    z-index: 9;
    &:checked + span {
      &.color:before {
        display: block;
        opacity: 1;
      }
      .checkbox-checked {
        display: block;
      }
    }
    + span {
        border: 2px solid $gray-lighter;
        width: 13px;
        height: 13px;
        margin-right: 10px;
        display: inline-block;
        cursor: pointer;
        vertical-align: middle;
        position: relative;
        &.color {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid $gray-lighter;
          &:before {
            content: '\f26b';
            font-family: $font-icon;
            font-size: 14px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -8.5px;
            margin-left: -5px;
            display: none;
            opacity: 0;
          }
        }
      .checkbox-checked {
        display: none;
        font-size: 14px;
        top: -3px;
        position: absolute;
        left: -2px;
      }
    }
  }
}

.custom-radio {
  width: 15px;
  height: 15px;
  position: relative;
  border: 2px solid $gray-lighter;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  margin-right: 10px;
  input[type="radio"] {
    opacity: 0;
    cursor: pointer;
    &:checked + span {
        display: block;
        background-color: $brand-primary;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        position: absolute;
        left: 1px;
        top: 1px;
    }
  }
}

.js-modal-content {
    padding: 15px;
}
i.done {
  color: #4cbb6c; 
}

/*====== Loading =======*/
.loading-tag {
    opacity: 0;
    visibility: hidden;
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
    position: fixed;
    top: 0 ;
    bottom: auto;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    margin: 0px auto;
    overflow: hidden;
    padding: 0;
    text-align: center;
    .wrapper:first-child {
      animation: rotate 1s infinite;
      height: 50px;
      width: 50px;
      text-align: center;
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      &:before, &:after {
        content: "";
        display: block;
        border-radius: 50%;
        height: 20px;
        width: 20px;
      }
      &:before {
        animation: ball1 1s infinite;
        background: #cb2025;
        box-shadow: 30px 0 0 #f8b334;
        margin-bottom: 10px;
      }
      &:after {
        animation: ball2 1s infinite;
        background: #00a096;
        box-shadow: 30px 0 0 #97bf0d;
      }
    }
    &.display {
      opacity: 1;
      visibility: visible;
    }
}
@keyframes rotate {
  0% { 
    -webkit-transform: rotate(0deg) scale(0.8); 
    -moz-transform: rotate(0deg) scale(0.8);
  }
  50% { 
    -webkit-transform: rotate(360deg) scale(1.2); 
    -moz-transform: rotate(360deg) scale(1.2);
  }
  100% { 
    -webkit-transform: rotate(720deg) scale(0.8); 
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f8b334;
  }
  50% {
    box-shadow: 0 0 0 #f8b334;
    margin-bottom: 0;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #97bf0d;
  }
  50% {
    box-shadow: 0 0 0 #97bf0d;
    margin-top: -20px;
    -webkit-transform: translate(15px,15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #97bf0d;
    margin-top: 0;
  }
}


/*====== Newsletter Popup  =====*/
.newsletter-popup {
  .fancybox-skin {
    padding: 0px !important;
  }
  .fancybox-inner {
    overflow: hidden !important;
  }
  .popup-image {
    height: 100%;
    overflow: hidden;
    transform: translate(-50%, -50%);
    top: 50% !important;
    left: 50% !important;
    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(103, 99, 99, 0.41);
    }
    img {
      display: none;
    }
  }
  .text_content {
    text-align: center;
    margin-top: 60px;
    .caption {
      position: absolute;
      top: 70px;
      text-align: center;
    }
    h4 {
      font-size: $font-size-h2; 
      text-transform: uppercase;
    }
    .mail-caption {
      line-height: 20px;
    }
  }
  .block_content{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, 0%); 
    form {
      @media (min-width: 768px) {
        width: 400px;
      }
    }
    input[type="text"] {
      width: 100%;
      text-indent: 10px;
      height: 41px;
      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 15px;
      }
    }
    .btn-primary {
      padding: 11px 15px;
      @media (min-width: 768px) {
          position: absolute;
          top: 0;
          right: 0;
      }
    }
  }
}

.fancybox-wrap {
  top: 50%;
  left: 50%;
  transition: all 0.5s ease-in-out;
  transform: scale(0);
  &.fancybox-opened {
    transform: scale(1);
    animation: zoomIn 1s;
    
  }
}

.fancybox-skin {
  position: relative;
  border-radius: 0px !important;
  .fancybox-error {
    white-space: normal;
    padding: 30px 20px;
    min-width: 270px;
    @media (max-width: 767px) {
      padding: 40px 20px;
    }
  }
}
.fancybox-close {
  background-image: none !important;
  background: #000;
  color: #fff;
  transition: all 0.5s ease-in-out;
  font-size: 0;
  top: 0px !important;
  right: 0px !important;
  &:before {
    content: '\f136';
    font-family: $font-icon;
    font-size: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -7px;
  }
  &:hover {
    background-color: $brand-primary;
    color: #fff;
  }
}

@keyframes snow {
  0% {
    background-position: 0px 0px, 0px 0px, 0px 0px;
  }
  50% {
    background-position: 500px 500px, 100px 200px, -100px 150px;
  }
  100% {
    background-position: 500px 1000px, 200px 400px, -100px 300px;
    
  }
}


