.slider-home {
  width: 100%;
  position: relative;
  display: block;
  /*=========== Slideshow =========*/
  .ls-layers { 
    > div {
      a {
        font-family: $theme-font;
        font-size: 16px !important;
        text-shadow: none;
        background: none;
        @media (max-width: 991px) {
          font-size: 14px !important;
          white-space: normal;
        }
        @media (max-width: 767px) {
          display: none !important; 
        }
        &:before, &:after {
          display: none;
        } 
        &.large_text, &.large_bold_grey, &.large_bold_darkblue, &.large_bold_white {
          font-size: $font-size-h2 !important;
          font-weight: 700 ;
          white-space: nowrap;
          @media (max-width: 991px) {
            font-size: 16px !important;
          } 
        } 
        &.big_white, &.big_yellow, &.big_bluee, &.big_orange, &.big_black {
          font-size: 44px !important;
          text-transform: uppercase !important;
          font-weight: normal !important;
          @media (max-width: 1500px) {
            font-size: 30px !important;
          }
          @media (max-width: 991px) {
            font-size: 20px !important;
          } 
        }
        // &.medium_grey, &.medium_text, &.medium_thin_grey, 
        // &.medium_bg_darkblue, &.medium_bold_red, &.medium_light_red, 
        // &.medium_light_red, &.medium_bg_red, &.medium_bold_orange,  
        // &.medium_bg_orange, &.medium_bg_asbestos, &.medium_light_white,
        // &.mediumlarge_light_white,  &.mediumlarge_light_white_center {
        //   font-family: $theme-font;
        //   font-size: 16px !important;
        // }
      }
    } 
  }
  

  /*=========== Banner =========*/
  .bc-banner-top  {
    background: $brand-third; 
    padding: 36px 0px 20px 0px;
    overflow: hidden;
    display: block;
    position: relative;
    margin-bottom: 25px;
    ul {
      margin: 0;
      padding: 0;
      li {
        float: left;
        position: relative;
        @media (min-width: 768px) {
          padding: 0px;
          margin-right: 25px;
          max-width: 31%;
        }
        @media (max-width: 767px) {
          padding: 10px 15px;
          max-width: 31%;
        }
        @media (max-width: 580px) {
            max-width: 100%;
        }
        &:first-child {
          .bc-services {
            padding-left: 64px;
          }
        }
        &:last-child {
          margin-right: 0px;
        } 
        >span {
          float: left;
          color: $textColor-1;
          font-size: 36px;
          &:before {
            font-size: 50px;
            font-family: $font-icon;
          }
          &.fa-paper-plane {
              transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -o-transform: rotate(45deg);
            position: absolute;
            top: -5px;
            &:before {
              content: '\f103';
            }
          }
          &.fa-retweet:before {
            content: '\f29c';
          }
          &.fa-gift:before {
            content: '\f1b9';
          }
        }
        .bc-services {
          padding-left: 69px;
          display: block;
          h4 {
            @include fontStyle--4();
            margin-bottom: 9px;
            color: $textColor-2;
          }
          p {
            color: #777777;
          }
        }
      }
    }
  }
}
.ls-bottom-slidebuttons {
  top: -22px; 
  a {
     background-image: none !important;
     border: 2px solid #fff;
     background-color: #fff;
     @include border-radius(50%);
     height: 11px !important;
     width: 11px !important;
     margin-right: 9px;
     &.ls-nav-active {
      background-color: $brand-primary ;
      border: 2px solid $brand-primary ;
     } 
  }
}
.ls-gui-element {
  background-image: none !important;
}

/*===== Block-img-home =====*/
.bc-banner-center {
  overflow: hidden;
  margin: 25px -15px 53px -15px;
  >div {
    float: left;
    @media (max-width: 767px) {
      max-width: 100%;
      padding-bottom: 15px;
    }
    .wraper-banner-center {
      overflow: hidden;
      position: relative;
      &:before, &:after {
        content: "";
        position: absolute;
        display: block;
        transition: all 0.5s ease-in-out;
        z-index: 1;
        top: 15px;
        bottom: 15px;
        left: 15px;
        right: 15px;
        background: transparent;
      }
      &:before {
        border-top: 2px solid $brand-primary;
        border-bottom: 2px solid $brand-primary;
        transform: scale(0, 1);
      }
      &:after {
        border-left: 2px solid $brand-primary;
        border-right: 2px solid $brand-primary;
        transform: scale(1, 0);
      }
      figure {
        margin: 0px;
        a {
          display: block;
        }
        img {
          transition: all 0.7s ease-in-out;
          transform: scale(1, 1);
          object-fit: cover;
        }
      }
      .description {
        position: absolute;
        top: 90px;
        left: 80px;
        text-transform: uppercase;
        font-family: inherit;
        z-index: 2;
        h2 {
          font-size: 26px;
          font-weight: 700;
          color: $textColor-1;
          margin-bottom: 14px;
        }
        a {
          font-size: 16px;
          font-weight: 700;
          color: $textColor-1;
          background: #fff;
          border: 1px solid #fff;
          padding: 13px 28px 13px 20px;
          transition: all 0.5s ease-in-out;
          position: relative;
          @include btn;
          &:hover {
            background: $brand-primary;
            border: 1px solid $brand-primary;
            color: #fff;
            transition-delay: 0.15s;
            @include btn-hover;
          }
        }
        p {
          font-size: 38px;
          font-weight: 100;
          color: $textColor-2;
          line-height: 35px;
          padding-bottom: 26px;
        }
      }
      
      &:hover {
        &:before, &:after {
          opacity: 1;
          transform: scale(1);
        }
        figure {
          img {
            transform: scale(1.09, 1.09);
          }
        }
      }
    }
    &:last-child {
      .description {
        h2.title {
          font-weight: 300;
          font-family: $theme-font-1;
          color: #fff;
          margin-bottom: 2px;
        }
        p {
          color: #e5d738;
          font-size: 36px;
          font-weight: 700;
          padding-bottom: 4px;
          &.discount {
            color: #fff;
            font-weight: 900;
            font-size: 60px;
          }
        }
      }
    }
  }
  
}

/*==== Banner mid home=====*/
.bc-mid-home  {
    height: 500px;
    overflow: hidden;
    position: relative;
    display: block;
    margin: 45px 0px 55px 0px;
    z-index: 9;
    background-size: cover;
    background-repeat: no-repeat;
    @media (max-width: 767px) {
        background-position: center center !important;
    }
  .item-wrapper {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s ease-in-out;
    width: 100%;
    height: 100%;
    img {
      display: none;
    }
    &:before {
      content:"";
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .bc-services {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      h2 {
        margin: 0px 0px 21px 0px;
        font-size: 60px;
        color: #fff;
        text-transform: uppercase;
        font-weight: 100;
        font-family: inherit;
        line-height: 58px;
      }
      p {
        color: $textColor-1;
        font-size: 52px;
        font-family: inherit;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 17px;
        line-height: 50px;
        &.discount {
          color: #fff;
          font-weight: 300;
          text-align: center;
        }
      }
    }
  }
}