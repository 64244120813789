#block-reassurance {
	border: 1px solid $gray-lighter;
	display: none;
	ul {
		margin: 0px;
		li {
			border-bottom: 1px solid $gray-lighter;
			padding: 7px 15px;
			&:last-child {
				border-bottom: none;
			}
			.h6 {
				font-weight: normal;
				font-size: $base-font-size;
			}
		}
		img {
			width: auto;
		}
	}
}