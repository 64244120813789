/*==== Search moblie ======*/
#content_menu_mobile {
  .search-block-top  {
    display: none;
  }
} 
form.search-box {
    position: relative;
    display: block;
    width: 70%;
    top: 12px;
    @media (max-width: 767px) {
      width: 100%;
      top: 0px;
    }
    p.search_top {
      margin-bottom: 0px;
      position: relative;
      .loadding {
        position: absolute;
        right: -10px;
        top: 5px;
        @media (max-width: 1200px) {
          right: -50px;
        }
        @media (max-width: 767px) {
          right: 30px;
        }
      }
    }
    input[type="text"].search_query {
      width: 0px;
      height: 30px;
      text-indent: 3px;
      display: block !important;
      border: 1px solid transparent;
      outline: none;
      @include prefix (transition,  all 0.8s ease-in-out);
      @media (max-width: 767px) {
        width: 100%;
        border: 1px solid $brand-primary;
      }
    }
    .button-search {
      background: none;
      font-size: 0;
      outline: none;
      position: absolute;
      top: -2px;
      border: none;
      cursor: pointer;
      left: 0px;
      color: #000;
      user-select: none;
      @include prefix (transition,  all 0.8s ease-in-out);
      @media (max-width: 767px) {
        left: auto;
        right: 10px;
      }
      &:before {
        content: '\f1c3';
        font-family: $font-icon;
        font-size: 30px;
      }
    }
    @media (min-width: 768px) {
      &:hover { 
        input[type="text"].search_query {
          width: 220px;
          border-bottom: 1px solid $brand-primary;
        }
        .button-search {
          left: 195px;
        }
      }
    }
  }

  /*====== Search Result ======*/
#search_result {
  .ajax_block_product {
    @media (min-width: 768px) {
      max-width: 25%;
      flex: 25%; 
    }
  }
  .products.product_show_list {
    .ajax_block_product  {
      max-width: 100%;
      flex: 100%;
    }
    .product-thumbnail {
      width: 20% !important;
      @media (max-width: 767px) {
        width: 40% !important;
        padding-bottom: 40%;
      }
    }
  }
}

/*====== Search Result =======*/
.show_result  {
  position: absolute;
  width: 250px;
  background: $bgColor;
  z-index: 999;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.36);
  @media (max-width: 767px) {
    width: 100%;
  }
  .result {
    max-width: 100%;
    flex: 100%;
    padding: 15px;
    #closedform {
      font-size: 18px;
      cursor: pointer;
      position: absolute;
      right: 0;
      top: 0;
      padding: 5px 8px;
      background: #000;
      color: $textColor-3;
      &:hover {
        background: $brand-primary;
      }
    }
    h1 {
      margin-top: 15px;
      margin-bottom: 15px;
      font-size: $font-size-h4;
    }
    li {
      overflow: hidden;
      display: block;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid $gray-lighter;
      &:last-child {
        border-bottom: none;
      }
      .product-thumbnail {
        padding-bottom: 30%;
        width: 25%;
        float: left;
        margin-right: 15px;
        .image-product, .image-product-hover {
          padding: 5px;
        }
      }
      .discount-percentage {
        display: none;
      }
      .information-search {
          float: left;
          width: 68%;
          .product-title {
            text-align: left;
            margin-top: 0px;
          }
          .star_content {
            text-align: left;
          }
          .product-price-and-shipping {
            text-align: left;
          }
      }
    }
  }
}

/*====== 404 Error ======*/
.search-widget {
  padding: 0px ;
  form {
    position: relative;
    display: block;
    width: 100%;
    top: 5px;
    input {
      width: 100%;
      height: 30px;
      text-indent: 5px;
    }
    button {
      border: none;
      background: none;
      position: absolute;
      top: 10px;
      right: 0;
      cursor: pointer;
      outline: none;
      user-select: none;
      &:hover {
        i {
          color: $textColor-1;
        }
      }
        .hidden-xl-down {
          display: none;
        }
    }
  }
}