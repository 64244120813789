/* Product */
h3.title_block {
  font-size: 30px;
  margin: 0;
  padding: 0;
}  
.block-content {
  .type-tab {
    margin-top: 31px;
    position: relative;
    display: block;
    .tab-toogle--mobile {
      @media (max-width: 767px) {
        position: absolute;
        top: -30px;
        right: 0px;
        cursor: pointer;
        &:before {
          content: "\f205";
          font-family: "FontAweSome";
          font-size: 14px;
          color: #333333;
        } 
        &.off {
          &:before {
            content: "\f204";
          }
        }
      }
    }
    ul.nav-tabs {
      border-bottom: none;
      float: left;
      padding-right: 30px;
      @media (min-width: 768px) {
        display: block !important;
      }
      @media (max-width: 767px) {
        position: absolute;
        z-index: 9;
        display: none;
        top: -14px;
        right: 0;
        padding-right: 0;
        max-width: 50%;
      } 
      li.nav-item {
        width: 100%;
        background: $gray;
        margin-bottom: 15px;
        padding: 20px;
        text-align: center;
        @include prefix(transition, all 0.35s ease-in-out);
        @media (max-width: 767px) {
          margin-bottom: 0px;
          text-align: right;
          padding: 10px 20px;
          img {
            display: none;
          }
        }
        &.active, &:hover {
          background: $brand-primary;
          a {
            color: #fff;
          }
        }
        a {
          font-family: $theme-font;
          font-size: 16px;
          color: $gray-darker;
        }
        p { 
          margin: 0px;
          padding-bottom: 5px;;
          img {
            width: auto;
          }
        }
      }
    }
    .tab-content {
      float: left;
      padding: 0;
      @media (max-width: 767px) {
        max-width: 100%;
      }
    }
    .bc-category-standard  {
      margin: 0px -15px;
      overflow: hidden;
    }
    .item {
      .thumbnail-container {
        .product-price-and-shipping {
          text-align: center;
        }
        .highlighted-informations  {
          margin: 0px ;
        }
      }
    }
  }
}
.ajax_block_product {
  position: relative;
  float: left;
  width: 25%;
  padding: 0px 15px 18px 15px;
  @media (max-width: 767px) {
    width: 50%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }
}
.thumbnail-container {
  position: relative;
  .product-thumbnail {
    border: 1px solid $gray-lighter; 
    position: relative;
    padding-bottom: 124%;
    display: block;
    height: 0;
    width: 100%; 
    img { 
      &.image-product {
        transition: all 0.5s ease-in-out;
        width: 100%;
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        padding: 15px;
        object-fit: cover;
        display: block;
      }
      &.image-product-hover {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        padding: 15px;
        transition: all 0.5s ease-in-out;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  .product-title {
    @include fontStyle--5();
    text-transform: initial;
    color: $gray-darker;
    text-align: center;
    font-weight: normal;
    margin-bottom: 9px;
    margin-top: 20px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    a {
      color: $gray-darker;
      transition: all 0.35s ease-in-out;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:hover {
        color: $textColor-1;
      }
    }
  }
  .product-price-and-shipping {
    text-align: center;
  }
  .highlighted-informations {
    text-align: center;
    background: #fff;
    @include border-radius(30px);
    @include box-shadow;
    position: absolute;
    display: block;
    visibility: hidden;
    opacity: 0;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%) scale(0);
    transition: all 0.5s ease-in-out;
    padding: 13px 10px 10px 10px;
    @media (max-width: 991px) {
      visibility: visible;
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
      transition-delay: 0s;
    }
    @media (max-width: 767px) {
      display: none;
    }
    .button_list {
      display: inline-block;
    }
    form {
      float: left;
      outline: none;
    } 
    .add-to-cart {
      cursor: pointer;
      display: block;
      border: none;
      font-size: 0;
      user-select: none;
      background: transparent;
      color: $textColor-2;
      padding: 0px 12px 0px 0px ;
      border-right: 1px solid $gray-lighter;
      outline: none;
      &:disabled {
        cursor: not-allowed;
      }
      &:before, &:after {
        display: none;
      }
      @media (max-width: 1200px) {
        padding: 0px 7px 0px 0px;
      }
      @media (max-width: 991px) {
        padding: 0px 10px 0px 0px;
      }
      @media (max-width: 865px) {
        padding: 0px 5px 0px 0px ;
      }
      i {
        margin-right: 0px;
      }
      &:hover {
        color: $textColor-1;
      }
    }
    a {
      color: $textColor-2;
      font-size: 0 !important;
      padding: 0px 12px ;
      display: block;
      float: left;
      border-right: 1px solid $gray-lighter;
      position: relative;
      height: 20px;
      @media (max-width: 1200px) {
        padding: 0px 4px;
      }
      @media (max-width: 991px) {
        padding: 0px 7px;
      }
       @media (max-width: 865px) {
        padding: 0px 3px;
      }
      &:last-child {
        border-right: none;
        padding-right: 0px;
      }
      &.quick-view {
        @media (max-width: 991px) {
          display: none;
        }
      }
      &.add_to_compare {
        i.material-icons {
          font-size: 20px;
        }
        .text_compare {
          font-size: 0 ; 
        }
      }
      &:hover {
        color: $textColor-1;
      }
      i.zmdi.zmdi-eye {
        font-family: $font-icon;
        font-size: 20px;
      }
    }
  }
  .description, .variant-links, .product-flags .discount {
    display: none;
    color: $gray-darker;
  } 
  .product-flags {
    .new {
      position: absolute;
      top: 10px !important;
      margin-left: 10px;
      background: #00adff;
      border-radius: 4px;
      padding: 3px 5px;
      color: #fff;
      text-transform: uppercase;
    }
  }
  &:hover {
    img {
      visibility: hidden;
      opacity: 0; 
      &.image-product-hover {
        visibility: visible;
        opacity: 1;
        
      }
    }
    .highlighted-informations {
      visibility: visible;
      opacity: 1;
      transform: translate(-50%, -50%)  scale(1);
    }
  }
}

.star_content {
  text-align: center;
  margin-bottom: 6px;
  .star {
    float: none !important;
    display: inline-block !important;
    width: 13px !important;
    height: auto !important;
    color: #fec006 !important;
    &:after {
      color: #fec006;
    }
    &.star_hover:after {
     color: #fec006;
    }
    &.star_on:after{
      color: #fec006;
    }
  }
}
.price {
  color: $textColor-1;
  @include fontStyle--4();
  font-weight: 900;
}
.old-price, .regular-price {
  font-size: $base-font-size;
  font-family: $theme-font;
  color: #777777;
  margin-left: 8px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    background: #cccccc;
    top: 50%;
    text-align: center;
  }
}
.discount-percentage {
     
    position: absolute;
    top: 0 !important;
    right: 10px;
    margin-top: 10px;
    background: #ff0a0a;
    border-radius: 4px;
    padding: 3px 5px;
    color: #fff;
}

/*===== Bestseller Product =====*/
.lang-fr  {
  .bc-bestsellerproduct  {
    .regular-price {
        margin-left: 0px;
        line-height: 30px;
    }
  }
}

.bc-bestsellerproduct  {
  .product_list {
    max-width: 100%;
    padding: 0px;
    margin: 25px -15px 0px -15px;
    overflow: hidden;
    .item {
      float: left;
      padding: 15px;
      position: relative;
      max-width: 33.3333333333%;
      width: 33.3333333333%;
      @media (max-width: 991px) and (min-width: 768px) {
        &:nth-child(3n+1) {
          clear: both;
        }
      }
      @media (max-width: 767px) {
        max-width: 50%;
        width: 50%;
        &:nth-child(2n+1){
          clear: both;
        }
      }
      @media (max-width: 580px) {
        max-width: 100%;
        width: 100%;
      }
      .thumbnail-container {
        .product-thumbnail {
          width: 45%;
          display: block;
          margin-right: 28px;
          float: left;
          overflow: hidden;
          padding-bottom: 0;
          height: auto;
          .image-product-best-seller { 
            display: block;
            padding: 15px;
            transform-origin: 0% 50%;
            transition: all 0.5s ease-in-out;
            transform-style: preserve-3d;
          } 
        }
        .product-title {
          text-align: left;
          // white-space: normal;
          // text-overflow: initial;
          margin-top: 0px;
          margin-bottom: 18px;
        }
        .star_content {
          text-align: left;
          overflow: hidden;
        }
        .highlighted-informations  {
          padding: 12px 5px 7px 5px;
          margin: 0px ;
          @media (max-width: 1200px) {
           padding: 15px 2px 10px 2px;
          }
          @media (max-width: 767px) {
            width: 90%;
          }
          .add-to-cart {
            padding: 0px 8px ;
            @media (max-width: 1200px) {
              padding: 0px 4px ;
            }
          }
          a {
            padding:  0px 6px;
            @media (max-width: 1200px) {
              padding: 0px 4px;
            }
            @media (max-width: 991px) {
              padding: 0px 2px;
            }
          }
        }
        .product-price-and-shipping {
            margin-bottom: 13px;
            text-align: left;
        }
        .price {
          font-size: $font-size-h3;
          padding-bottom: 15px;
          @media (max-width: 767px) {
              margin-bottom: 10px;
          }
        }
        .regular-price {
          font-size: 18px;
          font-weight: 700;
          @media (max-width: 1200px) {
              margin-left: 0px;
          }
        }
      }
    }
  }
}
.bc-newproduct {
  @extend .bc-bestsellerproduct;
  .thumbnail-container {
    .product-thumbnail {
      width: 30% !important;
      @media (max-width: 1200px) {
        width: 35% !important;
      }
    }
  }
}

/*===== bc-newproduct ====*/
.bc-newproduct {
  margin: 38px -15px 0px -15px;
  .info-bcfilterproduct-column {
    margin: 0px -15px;
  }
  .product_list {
    margin: 25px 0px 0px 0px;
  }
  .ajax_block_product {
    max-width: 50% !important;
    width: 50% !important;
    @media (max-width: 991px) {
      &:nth-child(2n+1){
        clear: both !important;
      }
      &:nth-child(3n+1){
        clear: initial !important;
      }
    }
    @media (max-width: 767px) {
        max-width: 100% !important;
        width: 100% !important;
    }
    .product-miniature {
      overflow: hidden;
      box-shadow:0px 1px 5px rgba(0, 0, 0, 0.19);
      padding: 15px;
      transition: all 0.3s ease-in-out;
      .thumbnail-container {
        .description {
          display: block;
        }
        .variant-links {
          display: block;
        }
      } 
      &:hover {
        box-shadow: 0 4px 9px rgba(0, 0, 0, 0.2);
        transform: translateY(-3px);
      }
    }
  }
}

/*==== Pagination ====*/
#js-product-list .pagination {
  .page-list {
    display: block;
    margin-top: 15px;
    li {
      border: none;
      background: none;
      a {
        border: 1px solid $gray-lighter;
        padding: 10px 14px;
        background: $bgColor;
        cursor: pointer;
        margin: 2px; 
        &.previous, &.next {
          padding: 10px 8px;
          i {
            font-size: 0;
            line-height: 40px;
            width: 20px;
            height: 38px;
            text-align: center;
            &:before {
              content: '';
              font-size: 14px;
              font-weight: 700;
              font-family: $font-icon;

            }
          }
          span {
            font-size: 0;
          } 
          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
        &.previous i:before {
          content: '\f2ea';
        }
         &.next i:before {
          content: '\f2ee';
        }
        &:hover {
          border: 1px solid $brand-primary;
          background: $brand-primary;
          color: $textColor-3 ;
          &.disabled {
            border: 1px solid $gray-lighter;
            background: $bgColor;
            color: #000000;
          }
        }
      }
      &.current {
        a {
          border: 1px solid $brand-primary;
          background: $brand-primary;
          color: $textColor-3 ;
        }
      }
    }
  }
}