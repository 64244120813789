$gray-darker: #333333;
$gray:  #eaeaea;
$gray-light: #e7e7e7;
$gray-lighter: #ebebeb;
$brand-primary: #ff9b00;
$brand-secondary: #1a1919;
$brand-success: #4cbb6c;
$brand-warning: #ff9a52;
$brand-info: #fefefe;
$brand-danger: #ff4c4c;
$label-color: $gray-darker;
$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: transparent;
$btn-secondary-color: $gray-darker;
$btn-secondary-bg: $gray-lighter;
$btn-secondary-border: transparent;
$btn-border-radius: 0;
$input-border-color: rgba(0, 0, 0, 0.25);
$headings-font-weight: 700;
$tooltip-bg: $brand-danger;
$border-radius: 0;
$extra-small-space: 0.3125rem;
$small-space: 0.625rem;
$medium-space: 1.25rem;
$large-space: 1.875rem;
$extra-large-space: 2.5rem;
$warning: #FF9A52;
$warning-hover: #FDE7BB;
$alert-warning-bg: rgba(255,154,82,0.3);
$alert-warning-border: $warning;
$alert-warning-text: $gray-darker;
$btn-warning-bg: $warning;
$btn-warning-color: white;
$btn-tertiary-color: #6C868E;
$btn-tertiary-hover: #BBCDD2; 
$base-font-size: 14px;
$font-size-lg: 1.143rem;
$font-size-sm: 1rem;   
$font-size-xs: 0.857rem;  
$font-size-h1: 36px;
$font-size-h2: 30px;
$font-size-h3: 24px;
$font-size-h4: 18px;
$font-size-h5: 14px;
$font-size-h6: 12px; 
$theme-font: 'Roboto', sans-serif;
$theme-font-1: 'Montserrat', sans-serif;
$font-icon: 'Material-Design-Iconic-Font';
$display1-size: 3.125rem;
$display1-weight: 600;
$display2-size: 2.188rem;
$display2-weight: 400;
$display3-size: 1.563rem;
$display3-weight: 400;
$display4-size: 1.25rem;
$display4-weight: 400;
$text-muted: $gray;
$NotoSansPath: "~notosans-fontface/fonts";
$NotoSansVersion: "1.0.1"; 

$colorTheme: #ff5002;
$colorTheme1: #fff;
$colorTheme2: #333333;
$bgColor: #fff;
$menuBg: #fff;
$textColor-1: #ff9b00;
$textColor-2: #000000;
$textColor-3: #ffffff;
$brand-third: #f3f3f3;
$copyright: #a8670c;