@mixin search-box {
  form {
    position: relative;
    input[type=text] {
      &:focus {
        outline: 3px $brand-primary solid;
        color: $gray-darker;
        background: white;
        + button .search {
          color: $brand-primary;
        }
      }
      border: none;
      padding: 10px;
      min-width: 255px;
      color: $gray;
      border: $input-btn-border-width solid $input-border-color;
    }
    button[type=submit] {
      position: absolute;
      background: none;
      border: none;
      bottom: 0.3125rem;
      right: 0.125rem;
      color: $gray;
      .search:hover {
        color: $brand-primary;
      }
    }
  }
}

@mixin box-shadow {
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
}

@mixin box-shadow-light {
  box-shadow: 2px 2px 4px 0px rgba(214, 214, 214, 0.2);
}

@mixin prefix($property, $value) {
  -#{$property}: #{$value};
  -webkit-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
}
@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
}
@mixin fontStyle--1() {
  font-family: $theme-font-1;
  font-size: $font-size-h1;
  font-weight: normal;
}
@mixin fontStyle--2() {
  font-family: $theme-font;
  font-size: $font-size-h2;
}
@mixin fontStyle--3() {
  font-family: $theme-font;
  font-size: $font-size-h3;
  font-weight: 700;
}
@mixin fontStyle--4() {
  font-family:  $theme-font;
  font-size: $font-size-h4;
}
@mixin fontStyle--5() {
  font-family: $theme-font;
  font-size: $font-size-h5;
}
@mixin fontStyle--6() {
  font-family: $theme-font;
  font-size: $font-size-h6;
}

@function menu-grid($totalWidth, $curCol, $totalCol) {
  @return $curCol * $totalWidth / $totalCol + 0px;
}

@mixin btn {
  &:before, &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(114, 114, 114, 0.22);
    transition: all 0.5s ease-in-out;
  }
  &:before {
    transform: scale(0, 1);
  }
  &:after {
    transform: scale(1, 0);
  }
}
@mixin btn-hover {
  &:before, &:after { 
    transform: scale(1);
    opacity: 0;
  }
}

@mixin icon-mobile ($c) {
    float: right;
    position: relative;
    i {
      color: $c;
      transition: all 0.35s ease-in-out;
      cursor: pointer;
      &.add, &.remove {
        position: absolute;
        right: 3px;
        top: 0;
      }
      &.remove {
        visibility: hidden;
        opacity: 0;
        transform: rotate(180deg);
      }
    }
    .collapse-icons {
      text-align: right;
    }
}
@mixin icon-mobile-active {
  i {
    &.add {
      opacity: 0;
      visibility: hidden;
      transform: rotate(-180deg);
    }
    &.remove {
      opacity: 1;
      visibility: visible;
      transform: rotate(0deg);
    }
  }
}