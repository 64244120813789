.bc-top  {
	overflow: hidden;
	margin-top: 38px;
	margin-bottom: 40px; 
	.bc-featured-categories-banner {
		overflow: hidden;
		margin: 0px -15px;
		.bc-featured-categories-content-banner {
			>div {
				float: left;
				padding: 15px;
				@media (max-width: 767px) {
					max-width: 50%;
					flex: 50%;
				}
				@media (max-width: 580px) {
					max-width: 100%;
					flex: 100%;
				}
			}
		}
		.title_block {
			margin: 0px 15px 19px 15px;
		}
		.bc-item {
			border: 1px solid $gray-lighter;
			box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
			height: 485px;
    		overflow: hidden;
			@media (max-width: 767px) {
				max-width: 100%;
				float: none;
			}
			h3.name-category {
				text-align: center;
				background: #f7f7f7;
				padding: 15px 10px;
				margin-bottom: 0px;
			}
			a.bc-img {
				display: block;
				position: relative;
				overflow: hidden;
				&:before {
					content: "";
					background: rgba(0, 0, 0, 0.22);
					position: absolute;
					width: 100%;
					height: 100%;
					transition: all 0.2s ease-in-out;
					opacity: 0;
					visibility: hidden;
					z-index: 9;
				} 
				&:hover {
					&:before {
						opacity: 1;
						visibility: visible;
					}
				}
			}
			.bc-cat-content {
			    padding: 15px 0px;
				ul {
					overflow: hidden;
					margin-bottom: 0px;
					li {
						width: 50%;
						float: left;
						padding: 5px 15px;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						display: block;
						@media (max-width: 580px) {
							width: 100%;
							float: none;
						}
						a {
							@include fontStyle--5();
							color: $textColor-2;
							&:hover {
								color: $textColor-1;
							}
							&:before {
								content: '\f2fb';
								font-family: $font-icon;
									font-size: 16px;
									padding-right: 5px;
							}
						}
					}
				}
			}
		}
	}
}