/*==== Left-column =====*/
.content_left {
	>div {
		margin-bottom: 30px;
	}
	.block-categories {
		ul {
			&.category-sub-menu {
				display: block;
				margin-bottom: 0px;
				margin-left: 10px;
				margin-top: 5px;
				li {
					border-bottom: 1px solid $gray-lighter;
					padding: 10px 0px;
					position: relative;
					&:last-child {
						border-bottom: none;
					}
					a {
						color: $textColor-2;
						&:hover {
							color: $textColor-1;
						}
					}
					.collapse-icons {
						float: right;
						padding: 0px;
						i {
							font-size: $base-font-size;
							cursor: pointer;
							position: absolute;
							right: 2px;
							&.remove {
								display: block;
							}
						}
						&[aria-expanded="true"] {
							.add {
								display: none;
							}
							.remove {
								display: block;
							}
						}
					}
					.in {
						display: block;
					}
					ul {
						padding-left: 15px;
						li {
							&:before {
								content: '\f2fb';
								font-family: $font-icon;
								font-size: 14px;
								padding-right: 5px;
								display: inline-block;
							}
						}
					}
				}
			}
		}
	}
	#search_filter_controls {
		display: none;
	}
	#search_filters {
		margin-bottom: 30px;
		h4 {
			margin-bottom: 15px;
			color: $textColor-2;
		}
		.btn.js-search-filters-clear-all {
			margin-bottom: 20px;
			@extend .btn-default:not(.button-search );
		}
		.facet {
			.h6 {
				font-size: $base-font-size;
				color: $textColor-1;
				@media (max-width: 767px) {
					display: none;
					margin-bottom: 0px;
				}
			}
			.title {
				display: none;
				@media (max-width: 767px) {
					position: relative;
					padding-bottom: 15px;
					display: block;
					cursor: pointer;
					.h6 {
						display: inline-block;
						color: $gray-darker;
					}
					.float-xs-right {
						@include icon-mobile($gray-darker);
					}
					&[aria-expanded="true"] {
						@include icon-mobile-active;
					}
				}
			}

			ul {
				@media (min-width: 768px) {
					display: block;
				}
				&.in {
					display: block;
				}
				li {
					padding: 10px 0px;
					border-bottom: 1px solid $gray-lighter;
					position: relative; 
					&:last-child {
						border-bottom: none;
					}
				}
			}
		}
	}
}

/*====== Content-wrapper ======*/
#subcategories {
	position: relative;
	.subcategory-heading {
		font-family: $theme-font;
    	font-size: $font-size-h4;
    	color: $textColor-2;
	}
	.cat_desc {
		display: none;
	}
	.owl-carousel {
		margin: 15px 0px 25px 0px;
		width: auto;
	}
	.owl-item {
		li {
			padding: 0px 15px;
			.subcategory-image {
				a {
					position: relative;
					display: block;
					&:after, &:before {
						content: "";
						background: rgba(0, 0, 0, 0.25);
						width: 100%;
						height: 100%;
						position: absolute;
						transition: all 0.35s ease-in-out;
						
						opacity: 0;
					}
					&:before {
						top: -100%;
						left: -100%;
					}
					&:after {
						top: 100%;
						left: 100%;
					}
				}
			}
			h5 {
				text-align: center;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -100%);
				transition: all 0.5s ease-in-out;
				opacity: 0;
				a {
					color: $textColor-3;
					font-weight: normal;
					font-size: $font-size-h3;
					font-family: $theme-font;
					text-transform: uppercase;
				}
			}
			&:hover {
				.subcategory-image a {
					 &:before, &:after {
						opacity: 1;
						left: 0;
						top: 0;
					}
				}
				h5 {
					opacity: 1;
					transform: translate(-50%, -50%);
				}
			}
		}
	}
	.owl-controls {
		position: absolute;
		top: -25px;
		right: 10px;
		.owl-next, .owl-prev {
			font-size: 0;
			&:before {
				content: "";
				font-size: 26px;
				font-family: $font-icon;
			}
		}
		.owl-next:before {
			content: '\f2fb';
		}
		.owl-prev:before {
			content: '\f2fa';
		}
	}
}

.left-column .text-sm-center {
	display: none;
}
#content-wrapper {
	@media (max-width: 767px) {
		max-width: 100%;
		flex: 100%;
	}
	.block-category {
		margin-bottom: 30px;
		display: block;
		overflow: hidden;
		.category-cover {
			width: 150px;
			height: auto;
			float: left;
			margin-right: 15px;
			@media (max-width: 767px) {
				float: none;
				margin: 0px auto 15px auto;
			}
		}
		.text-muted {
			color: $textColor-2 !important;
			p {
				&:first-child {
					margin-bottom: 15px;
				}
				margin-bottom: 5px;

			}
		}
	}
	#products {
		.products-selection {
			margin-bottom: 15px;
		}
		.total-products {
			@media (max-width: 767px) {
				padding-bottom: 15px;
			}
			i {
				float: left;
				cursor: pointer;
				margin-right: 5px;
				font-size: 24px;
				color: #888888;
				margin-top: 4px;
				&.active, &:hover {
					color: $textColor-2;
				}
			}
			.btn-primary {
				padding: 7px 10px;
				margin-left: 3px;
				opacity: 1;
				&:disabled {
					opacity: 0.6;
				}
				@media (max-width: 767px) {
					width: auto;
				} 
			}
		}
		.showing {
			display: none;
		}
		#js-active-search-filters {
			display: none;
		}
		.sort-by {
			margin-top: 8px;
			@media (max-width: 840px) and (min-width: 768px) {
				margin-top: 0px;
			}
		}
		.products-sort-order {
			button.select-title {
				border: 1px solid $gray-lighter;
				width: 100%;
				text-align: left;
				position: relative;
				cursor: pointer;
				height: 30px;
			}
			i {
				position: absolute;
			    right: 0;
			    top: 0;
			    border-left: 1px solid $gray-lighter;
			    height: 28px;
			    width: 28px;
			    text-align: center;
			    line-height: 28px;
			}
			&.open {
				.dropdown-menu {
					display: block;
					left: auto;
				    right: 15px;
				    width: 90%;
				    min-width: 290px;
				    @media (max-width: 1200px) {
						min-width: auto;
				    }
				    a {
				    	float: none;
				    	display: block;
				    	padding: 7px 10px;
				    }
				}
			}
		}
		.product-list {
			display: none;
		}
		.pagination {
		    float: none;
			margin: 30px 0px 15px 0px;
		}
		.text-xs-right.up {
			display: none;
		}
	}
}

/*===== Product_show_list ======*/
.product_show_list {
	.ajax_block_product {
		max-width: 100%;	
		flex: 100%;
		float: none;
		padding: 15px;
		margin: 15px;
		border: 1px solid $gray;
		transition: all 0.5s ease-in-out;
		position: relative;
		.thumbnail-container {
			overflow: hidden;
			z-index: 1;
			.product-thumbnail {
				width: 25%;
				padding-bottom: 25%;
				height: 0;
				float: left;
				margin-right: 15px;
				@media (max-width: 767px) {
					float: none;
					margin: 0px auto;
			    }
				.highlighted-informations {
					display: none;
				}
			}
			.product-flags {
				margin: 0px;
				.new {
					top: 10px;
				}
			}
			.product-list {
				display: block !important;
				.left-block {
					float: left;
					width: 48% ;
				    border-right: 1px solid $gray;
				    padding-right: 15px;
				    @media (max-width: 1200px) {
						width: 50% ;
				    }
				    @media (max-width: 880px) and (min-width: 768px) {
						width: 40% ;
				    }
				    @media (max-width: 767px) {
						float: none;
						border-right: none;
						padding-right: 0px;
						width: 100%;
						margin-top: 15px;
				    }
					.product-title {
						margin-top: 0px;
						text-align: left;
					}
					.description {
						display: block ;
						line-height: 20px;
					}
					.star_content {
						text-align: left;
						margin-top: 15px;
					}
					.variant-links {
						display: block;
					}
				}
				.right-block {
				    float: right;
				    position: relative;
				    @media (max-width: 767px) {
						float: none;
				    }
				    .product-price-and-shipping {
				    	@media (max-width: 767px) {
							text-align: left;
				    	}
				    }
				    .highlighted-informations-list {
				    	text-align: center;
				    	@media (max-width: 767px) {
							text-align: left;
					    }
				    	.button_list {
				    		display: inline-block;
				    		form {
							    text-align: center;
							    margin: 15px 0px;
							}
							a {
								font-size: 0;
								float: left;
								margin-right: 10px;
								width: 38px;
								height: 38px;
								border: 1px solid $gray-lighter;
								border-radius: 50%;
								position: relative;
								transition: all 0.5s ease-in-out;
								color: $gray-darker;
								&:last-child {
									margin-right: 0px;
								}
								span {
									font-size: 0;
								}
								i {
									font-size:20px; 
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
								}
								&:hover {
									color:  $textColor-3;
									background: $brand-primary;
									border: 1px solid $brand-primary;
								}
							}
				    	}
				    }
				}
			}
			>.product-title, >.comments_note,  >.product-description{
				display: none;
			}
		}
		&:hover {
			box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.31);
		}
	}
}
#product-availability {
	background: #98f19c;
	padding: 2px 5px;
	display: none;
	i {
		display: none;
	}
}

.variant-links {
	overflow: hidden;
	@media (max-width: 767px) {
		margin-bottom: 15px;
	}
	a {
		width: 20px;
		height: 20px;
		float: left;
		border: 1px solid $gray-lighter;
		border-radius: 50%;
		margin-right: 7px;
	}
}