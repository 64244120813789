
/*==== Colunm Left ====*/
#module-bcblog-tag,
#module-bcblog-post, 
#module-bcblog-categoryPost {
	.page-content {
		margin: 0px -15px;
		display: block;
		overflow: hidden;
		// display: inline-block;
	}
} 
#left-column {
	float: left;
	display: block;
	border-right: 1px solid $gray-lighter;
	@media (max-width: 767px) {
		float: none;
		max-width: 100%;
		flex: 100%;
		border-right: none;
	}
	.block {
		margin-bottom: 30px;
		h4 {
			@include fontStyle--4();
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 15px;
			position: relative;
			padding-bottom: 10px;
			color: $textColor-2;
			&:after { 
				content: "";
				position: absolute;
				bottom: 0px;
				left: 0;
				width: 40px;
				border-bottom: 2px solid $brand-primary;
			}
		}
		ul  {
			li {
				padding: 15px 0px;
				border-bottom: 1px solid $gray-lighter;
				overflow: hidden;
				&:last-child {
					border-bottom: none;
				}
				img {
					width: 90px;
					float: left;
					margin-right: 10px;
				}
				.icon-text {
					font-weight: normal;
					font-style: normal;
					font-size: 0;
				}
				a {
					color: $textColor-2;
					&:hover {
						color: $textColor-1;
					}
				}
				h3 {
					text-transform: capitalize;
					font-size: 14px;
					font-weight: normal;
					margin-bottom: 0px;
					line-height: 20px;
					a{
						color: $textColor-2;
						&:hover {
							color: $textColor-1;
						}
					}
				}
				.post-description {
					display: none;
				}
			}
			&.ul-lastest-post {
				.post-date-add {
					float: none;
				}
				.frame-date-author {
					display: none;
				}
			}
			&.ul-comment-recent {
				h3 {
					margin-bottom: 7px;
					a {
					text-transform: uppercase;
					} 
				}
				.frame-date-author {
					margin-top: 8px;
				}
			}
		}

		.comment-content {
			color: #5e514d;
		    text-align: left;
		    font-weight: 300;
		    font-size: 13px;
		    padding-bottom: 6px;
		    line-height: 20px;
		    text-align: justify;
		}
	}
	.tags_block {
		a {
			background: $gray;
			padding: 5px 10px;
			line-height: 30px;
			color: $gray-darker;
			&:hover {
				color: $textColor-1;
			}
		}
	}
}
.post-date-add {
	color: #888888;
	font-style: italic;
	float: left;
	font-size: 13px;
	padding-right: 15px;
	position: relative;
	.icon-text {
		&:before {
			content: '\f337';
		}
	} 
}
.icon-text {
	font-size: 0;
	&:before {
		content: '';
		font-family: $font-icon;
		font-size: 13px;
		padding-right: 5px;
	}
}
.post-author {
	color: #888888;
	font-style: italic;
	font-size: 13px;
	.icon-text {
		&:before {
			content: '\f207';
		}
	}
}
.post-comment {
	color: #888888;
	font-style: italic;
	font-size: 13px;
	float: left;
	padding-right: 15px;
	@media (max-width: 767px) {
		float: none;
	}
	.icon-text {
		&:before {
			content: '\f266';
		}
	}
}
/*===== Content Blog =====*/
.content {
	float: left;
	display: block;
	@media (max-width: 767px) {
		float: none;
		max-width: 100%;
		flex: 100%;
	}
	.page-heading {
		position: relative;
		padding-bottom: 10px;
		&:after {
			content: "";
			position: absolute;
			bottom: 0px;
			left: 0;
			width: 40px;
			border-bottom: 2px solid $brand-primary;
		}
		.heading-counter {
			float: right;
			text-transform: none;
			font-size: $base-font-size;
			font-weight: normal;
    		padding-top: 5px;
		}
	}
	.product_list {
		.bc-item {
			width: 50%;
			padding: 15px 15px 20px 15px;
			.post-name h3 {
				margin-top: 5px;
				text-align: justify;
				a {
					color: $textColor-2;
					&:hover {
						color: $textColor-1;
					}
				}
			}
			.frame-date-author {
			    float: left;
			    padding-top: 10px;
			    .post-author {
					float: right;
					@media (max-width: 767px) {
						float: none;
					}
				}
			}
		}
	}
	.content_sortPagiBar {
		.top-pagination-content {
			margin-bottom: 47px;
			.product-count, .rss{
				line-height: 40px;
			}
			.select.row {
				.sort-by {
					padding-top: 11px;
					text-align: right;
					@media (max-width: 767px) {
						text-align: left;
						margin-bottom: 5px;
					}
				}
			}
		}
	}
	.blog_content {
		img {
			width: auto;
			&.img_load {
				cursor: pointer;
			}
		}
	}
}
/*===== Lastest Blog Home =====*/
.bc-lastest-post{
	.row.bc-1-items-aline {
		margin: 36px -15px 0px -15px;
	} 
	.frame-date-author-comment {
		display: none;
	}
	h3 {
		margin: 29px 0px 13px 0px;
	}
	.view-more {
		float: left !important;
	}
} 
.bc-item {
	float: left;
	@media (max-width: 767px) {
		max-width: 50%;
		padding-bottom: 15px;
	}
	@media (max-width: 580px) {
		max-width: 100%;
	}
	.bc-bloglastest-img {
		display: block;
		position: relative;
		&:before, &:after {
			content: "";
			position: absolute;
			display: block;
			top: 15px;
			bottom: 15px;
			left: 15px;
			right: 15px;
			transition: all 0.5s ease-in-out;
			z-index: 1;
		}
		&:before {
			border-top: 1px solid $brand-primary;
			border-bottom: 1px solid $brand-primary;
			transform: scale(0, 1);
		}
		&:after {
			border-left: 1px solid $brand-primary;
			border-right: 1px solid $brand-primary;
			transform: scale(1, 0);
		}
		&:hover {
			&:before, &:after { 
				transform: scale(1);
			}
			img {
				opacity: 0.7;
			}
		}
	}
	.post-date-add {
		float: none;
		padding-top: 15px;
		padding-right: 0px;
	}
	h3 {
		font-size: 16px !important;
		text-transform: uppercase;
		@media (min-width: 768px) {
			display: block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		a.post_name {
			color: $textColor-2;
			&:hover {
				color: $textColor-1;
			}
		}
	}
	.post-description ,.post_description {
		color: #5e514d;
		text-align: left;
		font-weight: 300;
		font-size: 13px;
		padding-bottom: 6px;
		line-height: 20px;
		text-align: justify;
	}
	.bc-bloglastest-right-content p {
		overflow: hidden;
		margin-bottom: 5px;
	}
	.view-more {
		border: 1px solid $brand-primary;
		color: $brand-primary;
		font-size: $base-font-size;
		font-weight: 700;
		padding: 9px 23px;
		transition: all 0.5s ease-in-out;
		position: relative;
		float: right;
		@include btn;
		@media (max-width: 575px) {
			float: left;
			margin-top: 10px;
		}
		&:hover {
			background: $brand-primary;
			color: #fff;
			transition-delay: 0.15s;
			@include btn-hover;
		}
	}
}

/*===== Blog Detail ======*/
.img_detailblog {
	text-align: center;
	img {
		width: auto;
		max-width: 100%;
	}
}
.bc-blog_detail {
	margin: 50px 0px;
	h3 {
		margin-bottom: 5px;
	}
	.frame-date-author  {
		margin-bottom: 15px;
		.post-author {
			float: left;
			padding-right: 15px;
		}
	}
	.plpost_content {
	    line-height: 20px;
	    text-align: justify; 
	}
	.tag_blog {
		>span {
			font-weight: 700;
			float: left;
			padding-right: 10px;
		}
		.tag_list {
			>span {
				font-style: italic;
			}
		}
	} 
}
.blog-comment-list {
	margin-bottom: 50px;
	h3 {
		padding-bottom: 15px;
		border-bottom: 2px solid $brand-primary;
	}
	.comment-item {
		margin-bottom: 15px;
		&.last {
			margin-bottom: 0px;
		}
	}
	.comment-author {
		padding-bottom: 5px;
		.icon-text {
			font-size: 14px;
			padding-right: 10px;
			font-weight: 700;
		}
	} 
	.post-date-add {
		padding-bottom: 5px;
		.icon-text {
			font-size: 14px;
			padding-right: 10px;
			color: $gray-darker;
			&:before {
				display: none;
			}
		}
		float: none;
	}
	.comment-content {
		line-height: 20px;
	}
}
.pl_comment_form {
	h3 {
		padding-bottom: 15px;
		border-bottom: 2px solid $brand-primary;
	}
	.textarea {
		padding-bottom: 20px;
	}
}