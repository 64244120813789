.form-group {
	.form-control-label {
		float: left;
		text-align: right;
		padding-top: 10px;
		@media (max-width: 767px) {
			text-align: left;
			padding-top: 0px;
		} 
	}
	.email {
		float: left;
		@media (max-width: 767px) {
			padding: 0px;
			margin-bottom: 15px;
		}
	}
}
.ps-alert-success {
	i {
		display: none;
	}
}
