/*====== Page-stores ======*/
.page-stores {
	.store-item {
		margin-bottom: 30px;
	}
	.store-item-container {
		padding: 15px;
		div {
			float: left; 
			@media (max-width: 767px) {
				padding: 0px;
			}
			&.store-picture {
				padding: 0px;
				@media (max-width: 767px) {
					padding-bottom: 15px;
				}
			}
			&.store-description {
				line-height: 24px;
				h3 {
					font-size: 18px; 
				}
			}
			&.divide-left {
				border-left: 1px solid $gray-lighter;
				@media (max-width: 575px) {
					border-left: none;
				}
				th {
					float: right;
				}
				tr {
					line-height: 20px;
				}
				ul {
					margin: 0;
					padding: 0;
				}
			}
		}
	}
}