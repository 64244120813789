/*====== About us ======*/
.page-content.page-cms {
	.row {
		>div {
			@media (max-width: 767px) {
				max-width: 100%;
				flex: 100%;
				padding-bottom: 15px;
			}
		}
	}
	.page-heading  {
		display: none;
	}
	h3 {
		font-size: $font-size-h4;
	}
	.cms-block {
		p {
			line-height: 20px;
			margin-bottom: 15px;
		}
		ul {
			li {
				padding: 7px 0px 7px 20px;
				border-bottom: 1px solid $gray-lighter;
				color: $textColor-1;
				position: relative;
				&:last-child {
					border-bottom: none;
				}
				.icon-ok:before {
					content: '\f1e1';
					font-family: $font-icon;
					font-size: 14px;
					position: absolute;
				    top: 8px; 
				    left: 0px;
				    color: $textColor-1;
				}
			}
		}
	}
	.testimonials {
		position: relative;
		margin-bottom: 15px;
		.inner {
			border: 1px solid rgb(210, 208, 208);
			background: rgb(255, 255, 255);
			padding: 15px;
			line-height: 20px;
			span {
				text-indent: -5000px;
			    display: inline-block;
			    width: 20px;
			    height: 15px;
				&.before {
					background: url('../img/bl-before-bg.png') no-repeat;
					margin-right: 8px;
				}
				&.after {
					background: url('../img/bl-after-bg.png') no-repeat;
					margin-left: 8px;
				}
			}
		}
		&:after {
			content: " ";
		    display: block;
		    text-indent: -5000px;
		    position: absolute;
		    bottom: -15px;
		    left: 21px;
		    width: 15px;
		    height: 16px;
		    background: url('../img/testimon-after.png') no-repeat;
		}
		+ p {
			padding-left: 45px;
    		margin-bottom: 25px;
		}
	}
}

/*===== Secure Payment ======*/
.page-content.page-cms {
	h2, h3 {
		font-size: $font-size-h4;
		text-transform: capitalize;
		color: $textColor-2;
	}
	p {
		line-height: 20px;
		text-align: justify;
	}
}