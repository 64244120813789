.button-search {
	padding: 0px;
}
.btn-style-1{
	height: 38px;
	line-height: 38px;
	display: inline-block;
	background-color: $colorTheme;
	text-transform: uppercase;
	color: #fff;
	text-align: center;	
	padding: 0 15px;
	border-radius: 0;
	&:hover {
	background-color: $colorTheme;
	}
}  

@mixin btn-style-2(){
	height: 34px;
	line-height: 34px !important;
	padding: 0 15px;
	display: inline-block;
	background-color: $colorTheme;
	text-transform: none;
	@include fontStyle--6();
	font-weight: none;
	color: $colorTheme1 !important;
	text-align: center;	
	padding: 0 12px;
	border: none;
	border-radius: 4px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	span {
		font-size: inherit !important;
		color: inherit !important;
	}
	&:hover {
		color: $colorTheme1 !important;  
		background-color: $colorTheme2;
	}
}

.btn-style-3{
	height: 34px;
	line-height: 34px !important;
	display: inline-block;
	background-color: $colorTheme2;
	text-transform: none;
	font-weight: normal;
	color: $colorTheme1;
	text-align: center;	
	padding: 0 15px;
	border: none;
	border-radius: 4px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	span {
		color: inherit !important;
	}
	&:hover {
		color: $colorTheme1;  
	    background-color: $colorTheme; 
	}
}

@mixin btn-cart() {
	span {
		transition: all 0.2s ease-out;
		@extend .btn-style-1		
	}
	&:before {
		transition: all 0.2s ease-out;
		content: '';
		display: inline-block;
		float: right;
		@include btn-functional-general();	
	}
	@include btn-functional-content("\f07a"); 
	&:hover {
		span {
			background-color: $colorTheme2;
		}
		&:before {
			background: $colorTheme;
			content: "\f218";
		}
	}
}
@mixin btn-functional-general(){
	width: 38px;
	height: 38px;
	display: inline-block;
	font-size: 0;
	line-height: 38px;
	border-radius: 0px;
	border: none;
	background-color: $colorTheme2;
	color: $colorTheme1;
	text-align: center;
	padding: 0;
	transform: translate3d(0,0,0);
	&:hover {
		background-color: $colorTheme;
		color: $colorTheme1;
	}
}
@mixin btn-functional-content($content){
	&:before {
		content: $content;
		font-family: $fontIcon;
		display: block;
		font-size: em(16px);
		color: $colorTheme1;
	}	
}
button, 
.btn-unstyle, 
.btn.btn-primary,
.btn.btn-default {
  user-select: none;
  outline: none;
  box-shadow: none;
}
.btn-secondary, 
.btn-primary, {
  color: $btn-primary-color;
  background: $brand-primary;
  border: 1px solid $brand-primary;
  cursor: pointer;
  outline: none;
  position: relative;
  transition: all 0.5s ease-in-out;
  @include fontStyle--5();
  @include btn;
  padding: 10px 15px;
  @media (max-width: 767px) {
    width: 100%;
  }
  &:hover {
	border: 1px solid $brand-primary;
	background: transparent;
	color: $textColor-1;
    transition-delay: 0.15s;
    @include btn-hover;
  }
  &:disabled, &.disabled {
    background: $brand-primary;
    border: 1px solid $brand-primary;
    cursor: not-allowed;
    color: $btn-primary-color;; 
    opacity: 0.6;
    &:before, &:after {
      display: none;
    }
  }
  .material-icons {
    margin-right: 9px;
  }
}
.btn-default:not(.button-search ) {
    color: $textColor-1;
    background: transparent;
    border: 1px solid $brand-primary;
    @include fontStyle--5();
    outline: none;
    cursor: pointer;
    position: relative;
    transition: all 0.5s ease-in-out;
    @include btn;
    padding: 10px 15px;
    @media (max-width: 767px) {
      width: 100%;
    }
    &.disabled {
    	cursor: not-allowed;
    	background: $brand-primary;
    	color: $textColor-3;
    }
    &:hover { 
      border: 1px solid $brand-primary;
      background: $brand-primary;
      color: $textColor-3;
      transition-delay: 0.15s;
      @include btn-hover;
    } 
} 