#megamenu_top {   
    /*grid*/
    .col-sm-12 {
      width: 1170px; 
      @media(max-width: 1199px)
      {
        width: 970px;
      }
      @media(max-width: 991px)
      {
        width: 750px;
      }
      @media(max-width: 767px)
      {
        width: 100%;
      }
    }
    .col-sm-11 {
      width: menu-grid(1170,11,12);
      @media(max-width: 1199px)
      {
        width: menu-grid(970,11,12);
      }
      @media(max-width: 991px)
      {
        width: menu-grid(750,11,12);
      }
      @media(max-width: 767px)
      {
        width: 100%;
      }
    }
    .col-sm-10 {
      width: menu-grid(1170,10,12);
      @media(max-width: 1199px)
      {
        width: menu-grid(970,10,12);
      }
      @media(max-width: 991px)
      {
        width: menu-grid(750,10,12);
      }
      @media(max-width: 767px)
      {
        width: 100%;
      }
    }
    .col-sm-9 {
      width: menu-grid(1170,9,12);
      @media(max-width: 1199px)
      {
        width: menu-grid(970,9,12);
      }
      @media(max-width: 991px)
      {
        width: menu-grid(750,9,12);
      }
      @media(max-width: 767px)
      {
        width: 100%;
      }
    }
    .col-sm-8 {
      width: menu-grid(1170,8,12);
      @media(max-width: 1199px)
      {
        width: menu-grid(970,8,12);
      }
      @media(max-width: 991px)
      {
        width: menu-grid(750,8,12);
      }
      @media(max-width: 767px)
      {
        width: 100%;
      }
    }
    .col-sm-7 {
      width: menu-grid(1170,7,12);
      @media(max-width: 1199px)
      {
        width: menu-grid(970,7,12);
      }
      @media(max-width: 991px)
      {
        width: menu-grid(750,7,12);
      }
      @media(max-width: 767px)
      {
        width: 100%;
      }
    }
    .col-sm-6 {
      width: menu-grid(1170,6,12);
      @media(max-width: 1199px)
      {
        width: menu-grid(970,6,12);
      }
      @media(max-width: 991px)
      {
        width: menu-grid(750,6,12);
      }
      @media(max-width: 767px)
      {
        width: 100%;
      }
    }
    .col-sm-5 {
      width: menu-grid(1170,5,12);
      @media(max-width: 1199px)
      {
        width: menu-grid(970,5,12);
      }
      @media(max-width: 991px)
      {
        width: menu-grid(750,5,12);
      }
      @media(max-width: 767px)
      {
        width: 100%;
      }
    }
    .col-sm-4 {
      width: menu-grid(1170,4,12);
      @media(max-width: 1199px)
      {
        width: menu-grid(970,4,12);
      }
      @media(max-width: 991px)
      {
        width: menu-grid(750,4,12);
      }
      @media(max-width: 767px)
      {
        width: 100%;
      }
    }
    .col-sm-3 {
      width: menu-grid(1170,3,12);
      @media(max-width: 1199px)
      {
        width: menu-grid(970,3,12);
      }
      @media(max-width: 991px)
      {
        width: menu-grid(750,3,12);
      }
      @media(max-width: 767px)
      {
        width: 100%;
      }
    }
    .col-sm-2 {
      width: menu-grid(1170,2,12);
      @media(max-width: 1199px)
      {
        width: menu-grid(970,2,12);
      }
      @media(max-width: 991px)
      {
        width: menu-grid(750,2,12);
      }
      @media(max-width: 767px)
      {
        width: 100%;
      }
    }
    .col-sm-1 {
      width: menu-grid(1170,1,12);
      @media(max-width: 1199px)
      {
        width: menu-grid(970,1,12);
      }
      @media(max-width: 991px)
      {
        width: menu-grid(750,1,12);
      }
      @media(max-width: 767px)
      {
        width: 100%;
      }
    }
  }

/* Mega Menu*/
.header-bot  {
  background: $menuBg;
  &.bc-stickymenu-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    @media (max-width: 991px) {
      display: none;
    }
    >div {
      position: relative;
    }
    .bg-header {
      padding-left: 120px;
      width: 100%;
      position: relative;
    }
    .mega-menu {
      position: static;
    }
    .mega-menu.navbar-collapse {
      ul.menu-content {
        position: static;
        text-align: left;
        width: 100%;
      }
    }
  }
}
#content_menu_mobile {
  display: none;
  @media (max-width: 767px) {
    display: block;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    width: 300px;
    background: #333333;
    z-index: 9999;
    color: #fff;
    overflow: auto;
    @include prefix(transition, all 0.5s ease-in-out);
    @include prefix(transform, translate(-320px, 0px));
    .mega-menu.navbar-collapse {
      display: block;
    }
    &.opened {
      @include prefix(transform, translate(0, 0));
    }
  }
} 
@media (max-width: 767px) {
  .bc-menu-container {
    > div {
      padding: 0;
    }
  }
  .title-menu {
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 15px;
    background: $brand-primary;
  }
  #menu-icon {
    float: left;
    font-size: 20px;
    cursor: pointer;
    margin-top: 2px;
  }
}
.megamenu_top {
  text-align: center;
}
.mega-menu.navbar-collapse {
  @media (max-width:767px) {
      display: none;
  }
  >ul.menu-content {
    padding: 0px;
    margin: 0px;
    display: inline-block;
    @media (max-width: 767px) {
      display: block;
    }
    >li.menu-item  {
      list-style: none;
      float: left;
      display: block;
      position: relative;
      @media (max-width: 767px) {
        border-bottom: 1px solid #5f5f5f;
        cursor: pointer;
        float: none;
        &:last-child {
          border-bottom: none;
        }
      }
      .click-dropdown, .icon-drop-mobile {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 20px;
        right: 0px;
        text-align: center;
        transform: rotate(0deg);
        transition: all 0.5s ease-in-out;
        color: $gray-darker;
        display: block;
        z-index: 9;
        @media (max-width: 1200px) {
          top: 22px;
        }
        @media (max-width: 991px) {
          right: -6px;
        }
        @media (max-width: 767px) {
            color: $textColor-3;
            top: 16px;
            right: 8px;
        }
        &:before {
          content: "\f2f9";
          font-family: $font-icon;
          font-size: 16px;
          cursor: pointer;
        }
      }
      .click-dropdown{
        display: block;
        @media (max-width: 767px) {
          display: none;
        }
      }
      > .icon-drop-mobile {
        display: none;
        @media (max-width: 767px) {
          display: block;
          &.opened {
            transform: rotate(-180deg);
          }
        }
      }
      >a {
        padding: 22px 20px;
        color: $textColor-2;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 15px;
        transition: all 0.35s ease-in-out;
        position: relative;
        display: block;
        @media (max-width: 991px) {
          font-size: 14px;
          padding: 22px 13px;
        }
        @media (max-width: 870px) {
          padding: 22px 9px;
        }
        @media (max-width: 767px) {
          color: #fff; 
          font-weight: normal;
          padding: 15px ; 
        }
      }
      &.dropdown_menu_1 .dropdown {
        background: url('../img/bedroom_menu.png') no-repeat center !important;
        &:before {
          content: "";
          background: rgba(255, 255, 255, 0.78);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      &:hover {
        >a {
          color: $textColor-1;
        } 
        >.click-dropdown, >.icon-drop-mobile {
          color: $textColor-1;
        }
        .dropdown {
          &.animated {
            opacity: 1;
            display: block;
          }
        }
      }
      @media (max-width: 991px) {
        .dropdown.drop-display {
          opacity: 1;
          display: block;
          transform: translate(0%, 0%);
          + span.click-dropdown {
            transform: rotate(-180deg);
          }
        }
      }
      @media (max-width: 767px) {
        .dropdown.drop-display {
          display: block;
          opacity: 1;
        }
      } 
      .dropdown {
        @media (min-width: 768px) {
          position: absolute;
          z-index: 99;
          background: $bgColor; 
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
          left: 0;
          top: 60px;
          padding: 25px 0px; 
          border-top: 3px solid $brand-primary;
          transition: all 0.35s ease-in-out;
          opacity: 0;
          display: none;
          transform: translate(0%, 50%);
        }
        @media (max-width: 767px) {
          display: none;
        }
        .content-drop {
          padding: 0px;
          max-width: 100%;
          flex: 100%;
          overflow: hidden; 
           .menu-column {
            padding: 0px 25px;
            max-width: 100%;
            flex: 100%;
            float: left; 
            @media (max-width: 767px) {
              padding: 0px 15px;
            }
             ul.column-item { 
              text-align: left;
               li {
                position: relative;
                a {
                  padding: 10px 0px;
                  display: block;
                  color: $textColor-2;
                  @media (max-width: 767px) {
                    color: $textColor-3;
                    &:hover {
                      color: $textColor-1;
                    }
                  }
                }
                .click-dropdown {
                  display: none;
                }
                .icon-drop-mobile {
                  top: 6px;
                  transform: rotate(-90deg);
                  @media (max-width: 767px) {
                    top: 9px; 
                    display: block;
                    transform: rotate(0deg);
                    &.opened {
                      transform: rotate(-180deg);
                    }
                  }
                }
                ul.column-item {
                  padding: 0px 20px;
                  display: none;
                  @media (min-width: 768px) {
                    display: none;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 100%;
                    background: $bgColor; 
                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
                    padding: 15px 25px;
                    width: 100%;
                    z-index: 99;
                    border-top: 3px solid $brand-primary;
                  }
                }
                &:hover {
                  ul.column-item {
                      @media (min-width: 768px) {
                        display: block;
                        opacity: 1;
                    }
                  }
                  >a, .icon-drop-mobile {
                    color: $textColor-1;
                  }
                }
              }
            }
          }
        }
        .ajax_block_product {
          width: 100%;
          float: none;
          padding: 0px;
          .highlighted-informations {
            display: none;
            a {
              padding: 0px 12px;
              color: inherit;
            }
          }
        }
      }
    }
  }
}