.modal.quickview {
	.modal-body {
		padding: 0px 15px;
		.row {
			> div {
				@media (max-width: 767px) {
					max-width: 100%;
					flex: 100%;
				}
			}
		}
	}
	.product-cover {
		border: 1px solid $gray-lighter;
		margin-bottom: 15px;
	}
	i.zoom-in {
		display: none;
	}
	.mask {
		ul {
			text-align: center;
			.thumb-container {
				display: inline-block;
				text-align: center;
				margin: 5px;
				cursor: pointer;
				img {
					width: 80px;
					border: 1px solid $gray-lighter;
					&.selected {
						border: 1px solid $brand-primary;
					}
				}
			}
		}
	}
	h1 {
		@include fontStyle--4();
	}
	.product-prices {
		margin-top: 20px;
	}
	
	#product-description-short {
	    line-height: 20px;
	}
}
.modal-footer {
	display: block;
	@media (max-width: 767px) {
		border-top: none;
	}
	.panel-product-line {
		display: none;
	}
}
