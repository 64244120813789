@import "../node_modules/bourbon/app/assets/stylesheets/bourbon";
@import "partials/variables";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "partials/mixins";
@import "partials/fonts";
@import "partials/commons"; 
@import "partials/button";
@import "components/alert";
@import "components/drop-down";
@import "components/search-widget";
@import "components/mainmenu";
@import "components/checkout";
@import "components/customer";
@import "components/forgotten-password";
@import "components/renew-password";
@import "components/imageslider";
@import "components/featuredproducts";
@import "components/custom-text";
@import "components/categories";
@import "components/products";
@import "components/cart";
@import "components/block-reassurance";
@import "components/quickview";
@import "components/stores";
@import "components/footer";
@import "components/contact";
@import "components/errors";
@import "components/customization-modal";
@import "components/sitemap";
@import "components/blogs";

.page-index {
  .top-breadcrumb {
    display: none;
  }
  .row {
    margin: 0px;
  }
}
#wrapper {
  .top-breadcrumb {
    margin: 15px 0px;
    .breadcrumb {
      background: none;
      padding: 0;
      margin-bottom: 0px;
      ol {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      li {
        float: left;
        color: $textColor-2;
        &:before {
          content: '\f2fb';
          font-family: $font-icon;
          font-size: 16px;
          padding: 0px 7px;
        }
        &:first-child {
          &:before {
            display: none;
          }
        }
        &:first-child {
          a {
            font-size: 0;
            &:before {
              content: '\f175';
              font-family: $font-icon;
              font-size: 14px;
            }
          }
        }
        a {
          font-size: 16px;
          color: $textColor-2;
          &:hover {
            color: $textColor-1;
          }
        }
      }
    }
  }
}

/*==== Header====*/
.hidden-md-up.mobile {
  display: none;
  @media (max-width: 767px){
    display: block;
  }
}
.header-nav {
  font-size: $font-size-h6;
  background: $gray;
  @media (max-width: 767px) {
    background: $bgColor;
    padding: 10px 0px 0px 0px;
    font-size: $base-font-size;
  }
  .contact-box {
    float: left;
    margin-right: 36px;
    padding: 9px 0px;
    @media (max-width: 767px) {
      display: none;
    }
    i {
      margin-right: 9px;
      font-size: 13px;
    }
  }
  .user-info {
      float: left;
      padding:  9px 0px 8px 0px;
      @media (max-width: 767px) {
        padding: 0px ;
        float: none;
        text-align: right;
      }
      a {
        margin-right: 15px;
        color: $gray-darker;
        @media (max-width: 767px) {
          margin-right: 10px;
        }
        @media (max-width: 767px) {
          font-size: 0;
        }
        &:hover {
          color: $textColor-1;
        }
        &.account {
          display: none;
        }
        i {
          font-size: 13px;
          @media (max-width: 767px) {
            margin-top: -8px;
            font-size: 14px;
          } 
        }
        &.logout {
          i {
              @media (max-width: 767px) {
                font-size: 16px;
                margin-top: -8px;
              }
          }
        }
      }
  }
  .right-nav {
    text-align: right;
    @media (max-width: 767px) {
      display: none;
    }
    #_desktop_currency_selector,
    #_desktop_language_selector {
      display: inline-block;
      padding: 9px 0px;
    }
    .language-selector {
      margin-right: 5px;
      &:before {
        content: "\f173";
        font-size: 13px;
        font-family: $font-icon;
      }
    }
    .expand-more {
      padding-right: 4px;
    }
    i {
      font-size: 0;
      &:before {
        content: "\f2f9";
        font-size: 12px;
        font-family: $font-icon;
        position: absolute;
        top: 3px;
      }
    }
    ul.dropdown-menu {
      border: 1px solid $brand-primary;
      margin: 0;
      padding: 0;
    }
  }
  #currency-selector-label,
  #language-selector-label {
    display: none;
  }
}
select.link {
  display: none;
}
.currency-selector , 
.language-selector {
  ul {
    li {
      padding: 10px;
      img {
        width: 16px;
        cursor: pointer;
        float: left;
        margin-top: 3px;
        margin-right: 5px;
      }
      .dropdown-item {
        display: inline;
        padding: 0px;
        font-size: 14px;
      }
    }
  }
}
#currency-selector-label, 
#language-selector-label {
    @media (max-width: 767px) {
      float: left;
    }

}
.js-top-menu-bottom {
    @media (max-width: 767px) {
        padding: 10px 15px;
    }
}
#_mobile_currency_selector,
#_mobile_language_selector {
  .expand-more {
    @media (max-width: 767px) {
      color: #fff;
    }
  }
} 

.header-top {
  border-bottom: 1px solid $gray-lighter;
  padding: 47px 0px 45px 0px;
  background: $bgColor;
  @media (max-width: 767px) {
    padding: 0px 0px 30px 0px;
    border-bottom: none;
  }
  /*===== logo ====*/
  .position-static {
    text-align: right;
    max-width: 33.3333333333%;
    flex: 1;
    padding-top: 5px;
    @media (max-width: 767px) {
      display: none;
    }
  }
}
#_desktop_logo {
  @media (max-width: 767px) {
    display: none;
  }
}
.top-logo, #_desktop_logo {
  text-align: center;
  img{
    width: auto;
    @media (max-width: 320px) {
      width: 100%;
    }
  }
}

.top-logo {
    margin: 15px 0px;
    @media (max-width: 767px) {
        margin: 30px 0px;
    }
}
.header-bot {
  .logo-sticky {
    display: none;
  }
  &.bc-stickymenu-fixed {
    .logo-sticky {
      display: block;
      position: absolute;
      top: 18px;
      z-index: 99;
      width: 110px;
    }
  }
}

.header-home  {
  clear: both;
}

/*===== Banner header ====*/
.banner {
  background: $brand-primary;
  .banner-content {
    text-align: center;
    color: $textColor-3;
    line-height: 40px;
    position: relative;
  }
  .close {
    font-size: 0;
    border: none;
    position: absolute;
    right: 0;
    top: 4px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background: url('../img/close.png') no-repeat center;
    opacity: 1;
    &:before, &:after {
      display: none;
    }
    &:hover {
      background: url('../img/close.png') no-repeat center;
      border: none;
      transition-delay: 0s;
      transition: 0s;
    }
  }
  p {
    margin-bottom: 0px;
  }
  .ads-bar-link {
    color: $textColor-3;
    text-decoration: underline;
    &:hover {
      font-weight: 700;
    }
  }
}