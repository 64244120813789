/*===== Product Detail ====*/
.bc-detail {
	.product-cover {
		border: 1px solid $gray-lighter;
		padding: 15px;
		position: relative;
		.layer {
			position: absolute;
			top: 50%;
		    left: 50%;
		    transform: translate(-50%, -50%);
		    cursor: pointer;
		    opacity: 0;
		    visibility: hidden;
		    transition: all 0.3s ease-in-out;
		    i {
		    	font-size: 60px;
		    	color: $textColor-3;
		    }
		}
		&:hover {
			opacity: 0.6;
			cursor: pointer;
			.layer {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	.js-qv-mask {
		margin: 15px 0px;
		text-align: center;
		white-space: nowrap;
		overflow: hidden;
		ul {
			margin: 0px;
			padding: 0px;
			li {
				width: 128px;
				display: inline-block;
				margin-right: 15px;
				@media (max-width: 1200px) {
					width: 138px;
				}
				@media (max-width: 767px) {
					width: 102px;
				}
				&:last-child {
					margin-right: 0px;
				}
				img {
					border: 1px solid $gray-lighter;
					&.selected {
						border: 1px solid $brand-primary;
					}
				}
				&:hover {
					cursor: pointer;
					img {
						border: 1px solid $brand-primary;
					}
				}
			}
		}
	}
	.product-flags {
		display: none;
	}
	.star_content {
		text-align: left;
	}
	.description {
		line-height: 20px;
		color: $gray-darker ;
	} 
	.social-sharing {
		margin: 15px 0px;
	}
	.panel-product-actions {
		display: none;
	}
}
/*===== Product-cover-modal =====*/
.js-product-images-modal {
	.modal-dialog {
		max-width: 600px !important;
		.modal-content {
			background: none !important;
			border: none !important;
		}
		.modal-body {
			padding: 0px !important; 
			display: flex;
			@media (max-width: 767px){
				display: block;
			}
			figure {
				margin-bottom: 0px;
				background: $bgColor;
				padding: 15px;
				margin-right: 20px;
			}
			.image-caption {
				line-height: 20px;
				text-align: center;
				margin-top: 15px;
				@include fontStyle--5() ;
			}
			.thumbnails {
				display: block !important;
				ul {
					li {
						margin-bottom: 15px;
						border: 2px solid $gray-lighter;
						cursor: pointer;
						@media (max-width: 767px) {
							width: 120px;
							float: left;
							margin-top: 15px;
							margin-right: 15px;
							&:last-child {
								margin-right: 0px;
							}
						}
						&:hover {
							border: 2px solid $brand-primary;
						}
					}
				}
			}
		}
	}
}

.scroll-box-arrows {
	i {
		width: 35px;
		height: 35px;
		border: 1px solid $gray-lighter;
		position: relative;
    	bottom: 133px;
    	cursor: pointer;
    	background: $bgColor;
    	@media (max-width: 767px) {
			bottom: 100px;
    	}
    	&:before {
    		position: absolute;
    		top: 50%;
    		left: 50%;
    		margin-top: -7px;
    		margin-left: -5px;
    	}
    	&.left {
			float: left;
    	}
    	&.right {
    		float: right;
    	}
    	&:hover {
    		background: $brand-primary;
    		color: $textColor-3;
    		border: 1px solid $brand-primary;
    	}
	}
}

.product-info {
	.tab-pane.fade.in.active {
		background: $bgColor;
		padding: 15px 0px;
		position: relative;
		overflow: hidden;
		.product-description {
			line-height: 20px;
			@include fontStyle--5();
		}
		#product_comments_block_tab {
			+ span {
				padding: 0px 15px;
			}
		}

		/*====== Reviews =======*/
		#product_comments_block_tab {
			float: left;
			@media (max-width: 767px) {
				max-width: 100%;
				flex: 100%;
			}
			p {
				margin-bottom: 0px;
				text-align: justify;
			}
			>span {
				@include fontStyle--5();
				font-weight: 700;
			}
			.comment  {
				border-top: none;
				line-height: 20px;
				.comment_author_infos {
					margin-bottom: 15px;
					em {
						font-style: italic;
					}
				}
				li {
					padding-left: 0px;
					button.usefulness_btn {
						@extend .btn-primary;
						padding: 7px 10px;
						border-radius: 0px !important;
						font-weight: normal;
						@media (max-width: 767px) {
							margin-top: 10px;
							margin-left: 0px;
						}
					}
				}
			}
		}
		#new_comment_form_container {
			float: left;
			border-left: 1px solid $gray-lighter;
			@media (max-width: 767px) {
				max-width: 100%;
				flex: 100%;
				border-left: none;
			}
			.modal-dialog {
				margin: 0px;
			}
			.modal-content {
				border: none;
				.title {
					font-size: 16px;
					font-weight: 700;
					font-family: $theme-font;
					text-transform: capitalize;
					border-bottom: 2px solid $brand-primary; 
				}
				.product  {
				 	display: none;
				}
				.new_comment_form_content {
					width: 100%;
				}
				#criterions_list {
					border-bottom: none;
					padding-bottom: 0px;
					margin-bottom: 0px;
					label {
						text-transform: none;
					}
				}
				label {
					text-transform: none;
					font-weight: normal;
				}
				.fl {
					@media (max-width: 767px) {
						float: none;
					}
				}
				.fr {
					@media (max-width: 767px) {
						display: flex;
						float: none;
					}
					button {
						@extend .btn-primary;
						border-radius: 0px;
						margin-right: 10px;
						font-weight: normal;
						@media (max-width: 767px) {
							width: 50%;
						}
					}
					a {
						@extend .btn-default;
						margin-left: 10px;
						text-align: center;
						@media (max-width: 767px) {
							width: 50% !important;
						}
					}
				}
			}
		}
	}
	.product-features {
		.h6 {
			font-size: 18px;
			margin-top: 15px;
		}
		.data-sheet {
			overflow: hidden;
			border-top: 1px solid  $gray-lighter;
			border-bottom: 1px solid  $gray-lighter;
			.name, .value {
				border-top: 1px solid $gray-lighter;
				float: left;
				padding: 10px 0px;
				&:nth-of-type(even) {
					background: #f9f9f9;
				}
			}
			.name {
				border-right: 1px solid $gray-lighter;
				text-transform: uppercase;
				width: 30%;
				&:first-child {
					border-top: none;
				}
			}
			.value {
				&:nth-of-type(1) {
					border-top: none;
				}
				margin-bottom: 0px;
				width: 70%;
				padding: 10px 15px;
			}
		}
	}
	ul {
		li {
			margin-bottom: 0px !important;
			@media (max-width: 767px) {
				width: 100%;
				display: inline-flex;
			}
			a {
				@include fontStyle--5();
				text-transform: uppercase;
				padding: 10px 0px;
				margin-right: 40px;
				font-weight: 700;
				border: none !important;
				border-bottom: 3px solid transparent !important;
				&.active, &:hover { 
					border-top: none;
					border-right: none;
					border-left: none;
					border-bottom: 3px solid $gray-darker !important;
					color: $textColor-2 !important;
				}
			}
		}
	}
}
.product-discount {
	display: inline-block;
	.regular-price {
		margin-left: 0px;
		margin-right: 10px;
	}
}
.product-price {
	display: inline-block;
	position: relative;
	.price {
		font-size: 24px;
	}
	.discount-percentage {
		display: none; 
		font-size: 14px;
		font-weight: normal;
		color: $textColor-3;
		background: $brand-primary;
		text-align: center;
	    border-radius: 3px;
	    padding: 2px 5px;
	    position: absolute;
	    top: -10px;
	    right: -73px;
	}
}
.product-variants-item {
	margin-bottom: 15px;
	.control-label {
		font-weight: bold;
	}
	.form-control-select {
		width: 230px;
		margin-top: 15px;
		@media (max-width: 380px) {
			width: 100%;
		}
	}
	ul {
		margin-top: 15px;
		margin-bottom: 0px;
		li {
			display: inline-block;
			margin-right: 5px;
			input {
				display: none;
				&:checked {
					+span:before {
					 display: block;
					 opacity: 1;
					}
				}
			}
			.color {
				width: 22px;
				height: 22px;
				border: 1px solid $gray-lighter;
				display: inline-block;
				border-radius: 50%;
				position: relative;
				cursor: pointer;
				&:before {
					content: '\f26b';
		    		font-family: $font-icon;
		    		font-size: 14px;
		    		position: absolute;
				    top: 50%;
				    left: 50%;
				    margin-top: -8.5px;
				    margin-left: -5px;
				    display: none;
				    opacity: 0;
				}
			}
		}
	}
}
.product-add-to-cart {
	border-top: 1px solid $gray-lighter;
	border-bottom: 1px solid $gray-lighter;
	padding: 15px 0px;
	overflow: hidden;
	.control-label {
		display: none;
	}
	.product-quantity {
		float: left;
		@media (max-width: 400px) {
			float: none;
		}
		.qty {
			float: left;
			margin-right: 15px;
			.input-group-btn-vertical {
				position: relative;
				height: 52px;
				top: 0px;
				left: 0px;
				white-space: normal;
				margin-left: 7px;
				.btn {
				    width: 25px;
				    height: 25px;
				    text-align: center;
				    background: #f0f0f0;
				    color: $gray-darker;
				    margin: 0;
				    border: none;
				    transition: 0.3s;
				    position: relative;
				    cursor: pointer;
				    display: flex;
				    flex-direction: column;
				    margin-bottom: 2px;
				    &:last-child {
				    	margin-bottom: 0px;
				    }
				    i.touchspin-up:before {
						content: '\f2fc';
						font-family: $font-icon;
						font-size: 14px;
						position: absolute;
						display: block;
						top: 50%;
						left: 50%;
						margin-top: -7px;
						margin-left: -3.5px;
				    }
				    i.touchspin-down:before {
						content: '\f2f9';
						font-family: $font-icon;
						font-size: 14px;
						position: absolute;
						display: block;
						top: 50%;
						left: 50%;
						margin-top: -7px;
						margin-left: -3.5px;
				    }
				    &:hover {
				    	color: $textColor-1;
				    }
				}
			}
		}
		.add {
			float: left;
			margin-top: 5px;
		}
		input#quantity_wanted {
		    width: 80px !important;
		    text-align: center;
		    height: 52px;
		}
	}
	.button-wl-cp {
	    float: right;
	    position: relative;
	    top: 12px;
	    @media (max-width: 400px) {
			float: left;
			margin-bottom: 10px;
	    }
	    a.add_to_compare {
	    	margin-top: 12px;
	    	border: 1px solid $brand-primary;
	    	padding: 4px 2px;
    		border-radius: 3px;
    		margin-left: 20px;
    		color: $textColor-3;
    		background: $brand-primary;
	    	span {
		    	font-size: 0;
		    }
		    &:hover {
		    	background: #fff;
		    	border: 1px solid  $brand-primary;
		    	color: $textColor-1; 
		    }
	    }
	    .wishlist {
			width: 25px;
		    height: 25px;
		    text-align: center;
		    float: left;  
			a {
				font-size: 0;
    			position: absolute;
    			color: $textColor-2;
    			&:hover {
    				color: $textColor-1;
    			}
			}
		}
		i {
			font-size: 24px;
		}
	}
}
/*====== Social product =======*/
.social-sharing {
	ul {
		margin: 0px;
		li {
			width: 40px;
			height: 40px;
			display: inline-block;
			padding: 10px;
			border: 2px solid $gray-lighter;
			text-align: center;
			cursor: pointer;
			margin-right: 10px;
			transition: all 0.3s ease-in-out;
			&:last-child {
				margin-right: 0px;
			}
			a {
				color: $gray;
			}
			&.icon-gray:before {
				content: "";
				color: $gray;
				font-size: 24px;
				font-family: $font-icon;
			}
			&.facebook:before {
				content: '\f343';
			}
			&.twitter:before {
				content: '\f360';
			}
			&.googleplus:before {
				content: '\f34d';
			}
			&.pinterest:before {
				content: '\f3fc';
			}
			&:hover {
				border: 2px solid $brand-primary;
				&.facebook:before {
					color: #435f9f;
				}
				&.twitter:before { 
					color: #00aaf0;
				}
				&.googleplus:before {
					color: #e04b34;
				}
				&.pinterest:before {
					color: #ce1f21;
				}
			}
		}
	}
	span {
		display: none;
	}
	}
