.alert.alert-warning {
    // margin: 15px -15px;
}
.alert {
	margin-left: -15px;
	margin-right: -15px;
	ul {
		margin-bottom:  0px;
	}
}
.condition-label {
	a {
		font-weight: 700;
	}
}
/*==== Login page ====*/
.colums  {
	margin: 30px 0px 50px 0px;
	min-height: 160px;
	.page-content {
		border: none;
		margin: 0px;
	}
	h3, h2,	h1 {
		text-transform: uppercase;
		margin-bottom: 15px;
		font-size: $font-size-h3;
		color: $textColor-2;
	}
}
.form-group { 
	margin-bottom: 15px !important;	
	.form-control-comment {
		line-height: 40px;
	}
	.custom-checkbox {
		input[type="checkbox"] + span {
			float: left;
		}
		label {
			padding-left: 20px;
			display: block;
		}
	}
}
.page-authentication {
	.input-group-btn {
		.btn {
			cursor: pointer;
			padding: 11px 10px;
			margin-left: 0px;
			text-transform: uppercase;
			color: #fff;
			font-size: 12px;
			font-family: $theme-font;
			background: #757575;
		}
	}
	.forgot-password {
		text-align: center;
		padding: 15px 0px;
		a {
			color: $gray-darker;
			&:hover {
				color: $textColor-1;
			}
		}
	}
	.no-account {
		@extend .forgot-password;
		border-top: 1px solid $gray-lighter;
		margin-top: 15px;
	}
}
/*====== Register Page ======*/
footer.form-footer {
    text-align: center;
}
.form-control-valign {
    margin-top: 10px;
}

/*==== Wishlist Page =======*/
.page-content { 
	#mywishlist {
		margin: 0px -15px;
		.submit {
			padding-left: 0px !important;
			margin-right: 0px !important;
		} 
		label {
			font-size: $base-font-size !important;
		}
		#form_wishlist input.inputTxt {
			@media (max-width: 767px) {
				width: 100%;
			}
		}
		.footer_links.wishlist_footer  {
			border-top: 1px solid $gray-lighter;
			padding-top: 15px;
			.btn-default {
				float: none;
				@media (max-width: 767px) {
					margin-bottom: 15px;
				}
			}
		}
		.block-center {
			@media (max-width: 767px) {
				thead {
					float: left;
					padding-right: 30px;
				}
				th {
					display: block;
					border: none;
					border-bottom-width: 0px;
				}
				tbody {
					display: block;
				}
				td {
					border: none;
					display: block;
					&.wishlist_delete {
						text-align: left;
						padding: 0rem 0.75rem 0.75rem;
					}
				}
			}	
		}
		ul.wlp_bought_list {
			li {
				@media (max-width: 767px) {
					padding-bottom: 20px;
				}
			}
		}
		.product-name {
			@include fontStyle--5();
			color: $textColor-2;
			line-height: 20px;
			&:hover {
				color: $textColor-1;
			}
		}
	}
}
.required {
	margin: 15px 0px;
}

/*===== My Account =======*/
.page-my-account {
	.page-content {
		.row {
			margin: 0px -15px;
			.links {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				a {
					margin-bottom: 15px;
					text-align: center;
					text-transform: uppercase;
					@media (max-width: 767px) {
						display: block;
						padding-bottom: 15px;
						float: left;
					}
					.link-item {
						padding: 30px 15px;
						border: 1px solid $gray-lighter;
						background: $bgColor;
						display: block;
					}
				}
			}
		}
	}
}

/*====== Contact-us Page ======*/
.contact-form {
	.form-group{
		&.row {
			margin: 0px -15px;
			.form-control-label {
				padding: 10px 15px 0px 15px;
			}
		}
		>div {
			padding: 0px 15px;
		}
	}
	.btn-primary {
		margin-bottom: 15px;
	}
}

/*====== Compare Page =====*/
.container-compare {
	h2 {
		@include fontStyle--3();
		text-transform: uppercase;
		display: none;
	}
}
.ajax_block_product-comparison_infos {
	@media (max-width: 767px) {
		width: 50% !important;
	}
	@media (max-width: 480px) {
		width: 100% !important;
	}
	h5 {
		min-height: auto !important;
		padding: 0px !important;
		font-size: $base-font-size;
		font-weight: normal;
		a {
			color: $textColor-2;
			&:hover {
				color: $textColor-1;
			}
		}
	}
	.product_desc {
		color: $gray-darker;
	}
	.price-percent-reduction , .product_discount {
		display: none;
	}
	.new-box {
		background-color: #00adff;
		border-radius: 3px;
		padding: 2px 10px!important;
    	text-transform: uppercase;
	}
	.btn-primary {
		margin-bottom: 15px;
	}
}

/*===== Check Out =====*/
.checkout-step {
	.nav-inline {
		.nav-item {
			position: relative;
			span {
				top: 8px;
	    		position: absolute;
			}
		}
	}
}

/*====== Address Page =======*/
article.address {
	margin:  0px -15px;
}
.address-body {
	line-height: 20px;
	margin-bottom: 15px;
}
.address-footer {
	margin-bottom: 15px;
}

/*===== Brand Page =====*/
ul {
	li.brand {
		margin-bottom: 15px;
		padding: 15px;
		display: inline-block;
		text-align: center;
		.brand-img {
			width: 120px;
			margin: 0px auto 15px auto;
		}
		.brand-infos {
			h3 {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				font-size: 16px;
				text-transform: initial;
				margin-bottom: 5px; 
				a {
					color: $gray-darker;
					&:hover {
						color: $textColor-1;
					}
				}
			}
		}
		.brand-products a {
			color: $gray-darker;
			&:hover {
				color: $textColor-1;
			}
		}
	}
}

/*====== Order-hitory Wishlist =======*/
.orders.hidden-md-up {
	display: none;
	@media (max-width: 767px) {
		display: block;
	}
	.order {
		>  div {
			margin: 0px;
			>div {
				@media (max-width: 767px) {
					float: left;
					width: 83.3333333333%;
					&.text-xsright {
						width: 16.6666666667%;
						float: left;
					}
				}
			}
		} 
	}
}
.table-striped {
	@media (max-width: 767px) {
		display: none;
	}
}
.label-pill.bright {
	background: none !important;
}
#order-infos {
	border: 1px solid $gray-lighter;
	padding: 15px;
	margin-bottom: 15px; 
	.box {
		> div {
			> div {
				padding: 7px 15px;
			}
		}
		ul {
			margin-bottom:  0px;
			li {
				padding: 7px 0px;
			}
		}
		a.button-primary {
			text-decoration: underline;
		}
	}
}
#order-history {
	border: 1px solid $gray-lighter;
	padding: 15px;
	margin-bottom: 15px; 
	.history-lines {
		display: none;
		@media (max-width: 767px) {
			display: block;
		}
	}
}
.addresses {
	overflow: hidden;
	margin: 0px -15px;
	> div {
		float: left;
		article {
			border: 1px solid $gray-lighter;
			padding: 15px;
			margin-bottom: 15px;  
			address {
				line-height: 20px;
			}
		}
		
	}
}
.box.hidden-sm-down {
	@media (max-width: 767px) {
		display: none;
	}
	.line-total {
		font-weight: 700;
	}
}
.shipping-lines,
.order-totals.hidden-md-up.box,
.order-items.hidden-md-up.box {
	display: none;
	@media (max-width: 767px) {
		display: block;
		border: 1px solid $gray-lighter;
		margin-bottom: 15px;
		padding: 15px;
	}
}
.order-items.hidden-md-up.box {
	.qty {
		>div {
			>div {
				float: left;
    			width: 33.3333333333%;
			}
		}
	}
}
.order-totals.hidden-md-up.box {
	.order-total {
		margin: 0px;
		>div {
			line-height: 20px;
			float: left;
    		width: 66.6666666667%;
    		&.text-xsright {
				float: left;
	    		width: 33.3333333333%;
			}
		}
	}
}
.shipping-lines {
	li {
		line-height: 20px;
	}
}
.box.messages {
	.message {
		margin-bottom: 10px;
		>div {
			line-height: 20px;
		}
	}
}
