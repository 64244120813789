#_mobile_cart {
  @media (max-width: 767px) {
    float: right;
    margin-left: 10px;
  }
  .cart-products-count , .hidden-sm-down {
    @media (max-width: 767px) {
      display: none;
    }
  }
  .value {
    color: $textColor-1;
  }
}
/*===== Popup Cart ======*/
.fade.in {
  opacity: 1;
  background: rgba(0, 0, 0, 0.27);
  .modal-content {
    border-radius: 0px;
  }
  .modal-header {
    border-bottom: none;
    display: block;
    padding: 25px;
  }
  .modal-body {
    padding: 15px 0px;
  }
  .modal-dialog {
    max-width: 1140px;
    transform: translate(0, 0);
    margin-top: 60px;
    position: relative;
    .modal-title {
      color: #4bac52;
      font-weight: normal;
      font-size: 16px;
    }
    .close {
      position: absolute;
      right: 0;
      top: 0;
      background: #000;
      padding: 10px 15px;
      opacity: 1;
      color: #fff;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      width: 36px;
      height: 36px;
      font-weight: normal;
      &:before {
        content: '\f136';
        font-family: $font-icon;
        font-size: 24px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -12px;
        margin-left: -7px; 
      }
      span {
        display: none;
      }
      &:hover {
        background: $brand-primary;
      }
    }
    .row {
      margin: 0px;
    }
    .divide-right {
      border-right: 1px solid $gray-lighter;
      @media (max-width: 767px) {
        border-right: none;
        padding: 0px 0px 15px 0px;
        img {
          max-width: 350px;
          margin: 0px auto 15px auto;
          display: block;
        }
      }
      .product-name {
        font-size: 16px;
        font-family: $theme-font;
      }
      
      p {
        color: $textColor-1;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: 900;
      }
      span {
        line-height: 25px;
      }
    }
    .cart-content {
      .cart-products-count {
        font-size: 16px;
        font-family: inherit;
        border-bottom: 1px solid $gray-lighter;
        padding-bottom: 15px;
        font-weight: normal;
      }
      p {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 15px;
        strong {
          font-weight: normal;
          font-size: 14px;
          min-width: 120px;
          display: inline-block;
        }
      }
      .btn-secondary {
        padding: 11px 15px;
        @media (max-width: 767px) {
          margin-bottom: 15px;
        }
      }
      .btn-primary {
        @extend .btn-default;
      }
    }
  }
}

/*======= Product line ========*/
.header-top {
  .icon-cart {
    position: relative;
    float: right;
    background: $brand-primary;
    padding: 7px;
    border-radius: 5px;
    &:hover {
      .cart-grid {
        transform: translateY(0%);
        opacity: 1;
        visibility: visible;
      }
      .cart-products-count {
        top: 5px;
      }
    }
    .header {
      color: $textColor-3;
      a {
        font-size: $base-font-size;
        font-family: $theme-font;
        color: $textColor-3;
      } 
    }
    i {
      font-size: 18px;
    }
    .hidden-sm-down {
      display: none;
    }
    .cart-products-count {
      margin-right: 5px;
      position: absolute;
      top: -7px;
      left: 9px;
      border-radius: 50%;
      background: #ff0a0a;
      width: 17px;
      height: 17px;
      text-align: center;
      font-size: 12px;
      transition: all 0.5s ease-in-out;
      box-shadow: 0px 2px 5px rgba(103, 103, 103, 0.5);
    }
    .value {
      font-size: 16px;
      font-family: inherit;
      font-weight: bold;
      
    }
  }
  .cart-grid {
    background: #fff;
    @include box-shadow;
    margin: 0 !important;
    position: absolute;
    right: 0px;
    padding: 15px;
    min-width: 290px;
    @include prefix(transition, all 0.35s ease-in-out);
    display: block !important;
    transform: translateY(-10%);
    visibility: hidden;
    opacity: 0;
    z-index: 99;
    .cart-detailed-totals {
        line-height: 24px;
        margin: 15px 0px;
    } 
    .js-cart {
      padding: 0px;
      margin-bottom: 0px;
    }
    .card {
      border: none;
      @include prefix(box-shadow, none);
      text-align: left;
      .card-block {
        &:last-child {
          .cart-summary-line:last-child{
            border-bottom: none;
          }
        }
        .cart-summary-line {
          border-bottom: 1px solid $gray;
          padding: 7px 0px;
          .value {
            float: right;
            font-weight: normal;
            color: $gray-darker;
          }
          &.cart-total .value {
            font-weight: bold;
          }
        }
        .btn-primary {
          width: 100%;
        }
      }
      
    }
  }
  .product-line-grid-left {
    float: left;
    padding-left: 0px;
  }
  .cart-items {
    overflow-y: auto;
    max-height: 270px;
      .cart-item {
        position: relative;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid $gray-lighter;
        &:last-child {
          border-bottom: none;
        }
        .product-line-grid-body {
          float: left;
          padding-left: 0px;

        .product-line-info {
          font-size: 14px;
          color: $gray-darker;
          padding-bottom: 5px;
          padding-right: 5px;
          &:first-child {
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          &:nth-of-type(3) {
            .label {
              display: none;
            }
          }
          a.label {
            font-size: 12px;;
          } 
          .value {
            &:first-child {
              display: none;
            }
            font-size: inherit;
            color: inherit;
            font-weight: normal;
          }
        }
      }
      .cart-line-product-actions {
        position: absolute;
        right: 0;
      } 
      .price,  .product-price{
        font-size: 14px;
        color: $textColor-2;
        font-weight: normal;
      }
    }
  }
}

/*===== Order Page =====*/
.page-order {
  #wrapper {
    margin: 15px 0px;
  }
}
.checkout-step {
  border: 1px solid $gray-lighter;
  background: $bgColor;
  padding: 15px;
  margin-bottom: 15px;
  &.-reachable.-complete {
    .content {
       display: none;
    }
  }
  &.-current.js-current-step {
    .content {
      display: block;
    }
  }
  h1 {
    font-size: 18px;
  }
  .content {
    float: none;
  }
  .step-edit {
    font-size: 14px;
    font-weight: normal;
    float: right;
    color: $textColor-2 !important;
    cursor: pointer;
    i {
      font-size: 14px;
    }
  }
  .float-xs-left {
    float: left;
  }
  .js-terms {
    cursor: pointer;
  }
  .address-selector {
    .address-item {
      border: 1px solid #ebebeb;
      margin-bottom: 15px;
    }
    label.radio-block {
      padding: 15px;
    }
    .address-alias {
      text-transform: uppercase;
      font-size: 14px;
    }
    .address {
      margin-top: 15px;
      line-height: 20px;
      font-weight: normal;
    }
    .address-footer {
      border-top: 1px solid #ebebeb;
      padding: 15px;
      a {
        color: #000 !important;
        margin-right: 15px;
        &:hover {
          color: $textColor-1 !important;
        }
      }
    }
  }
  dl {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    dt, dd { 
      flex: 45%;
      float: left;
      background: #ebebeb;
      padding: 7px; 
      font-weight: normal;
      margin-bottom: 2px;
      &:nth-of-type(even) {
        background: #f6f6f6;
      }
    }
    dt {
      margin-right: 2px;
    } 
  }
}
.text-muted {
  color: #333333 !important;
}
.js-cart {
  padding: 15px;
  margin-bottom: 15px;
  .cart-summary-products {
    p{
      display: inline-block;
      float: right;
      &:first-child {
        float: left;
      }
      a {
        color: $textColor-1;
      }
    }
  }
  .collapse {
    clear: both;
    &.in {
      display: block;
    }
    ul {
      margin-bottom: 0px;
      li {
        padding: 5px 0px;
        .media-left {
          width: 30px;
          float: left;
          margin-right: 10px;
        }
        .media-body {
          white-space: nowrap;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 7px;
        }
      }
    }
  }
  .cart-summary-line  {
    clear: both;
    line-height: 20px;
    .label {
      float: left;
    }
    .value {
      float: right;
      font-weight: 700;
    }
    &.cart-total {
      .value {
        color: $textColor-1;
      }
    }
  }
}
.delivery-options-list {
  .carrier-name {
    font-size: 14px;
    padding-left: 10px;
  }
  textarea {
    width: 100%;
    margin-bottom: 15px;
  }
}

/*====== Order-confirmation =======*/
.page-order-confirmation{
  .card {
    border: 1px solid #ebebeb;
    margin-bottom: 15px;
  }
  .card-block {
    padding: 15px;
    .card-title {
      font-size: 18px;
    }
  }
  table {
    width: 100%;
    border-top: 1px solid #eee;
    margin-top: 15px;
    tr {
      line-height: 24px;
      td:last-child {
        text-align: right;
      }
      &.font-weight-bold {
        td{
          color: $brand-primary;
        }
      }
    }
  }
  .order-line {
    margin-bottom: 15px;
    >div {
      @media (max-width: 575px) {
        padding-bottom: 15px;
        text-align: center;
      }
    }
    .qty { 
      .row {
        div {
          float: left;
          width: 41.6666666667%;
          text-align: center;
          &:nth-of-type(2) {
            float: left;
            width: 16.6666666667%;
          } 
        }
      }
    }
  }
  #order-details {
    margin-top: 15px;
    li {
      line-height: 20px;
    }
  }
}
.featured-products,  #content-hook-order-confirmation-footer {
    .products {
      margin: 0px -15px;
      overflow: hidden;
    }
    .js-product-miniature, .product_show_list {
      width: 25%;
      float: left;
      padding: 15px;
      @media (max-width: 767px) {
        width: 50%;
      }
       @media (max-width: 580px) {
        width: 100%;
      } 
    } 
    .all-product-link {
      clear: both;
      font-size: 16px;
      float: right;
    }
  }

/*====== Page Cart ======*/
.page-cart #columns{
  .cart-grid {
  	margin-bottom: 30px;
  	.card.cart-container {
	    margin-bottom: 30px;
	}
	.cart-grid-right {
		@media (max-width: 991px) {
			margin-top: 30px;
		}
	}
    .card-block {
      padding: 15px;
      h1 {
        margin-bottom: 0px;
      }
    }
    .cart-overview {
      border-top: 1px solid #ebebeb;
      li {
        overflow: hidden;
        border-bottom: 1px solid  #ebebeb;
        padding: 15px 0px;
        &:last-child {
          border-bottom: none;
        }
        .product-line-grid {
          > div {
            float: left;
            &.product-line-grid-right {
              input {
                height: 52px;
                text-align: center;
                float: none;
                display: block;
                margin-bottom: 10px;
              }
              .input-group-btn-vertical {
                display: block;
                float: none;
                margin-left: 2px;
                .btn {
                  width: 25px;
                  height: 25px;
                  border: 1px solid #ebebeb;
                  margin-bottom: 2px;
                  cursor: pointer;
                  position: relative;
                  display: block;
                  color: $gray-darker;
                  &:last-child {
                    margin-bottom: 0px;
                  }
                  i {
                    &:before {
                    	content: '';
	                    font-family: $font-icon;
	                    position: absolute;
	                    top: 50%;
	                    left: 50%;
	                    margin-top: -10px;
	                    margin-left: -5px;
                    }
                    &.touchspin-up:before {
                        content: '\f2fc';
                    }
                    &.touchspin-down:before {
                      content: '\f2f9';
                    }
                  }
                  &:hover {
                  	color: $textColor-3;
                  	background: $brand-primary;
                  	border: 1px solid $brand-primary;
                  }
                }
              }
            }
            &.product-line-grid-left {
            	@media (max-width: 767px) {
					width: 25%;
            	}
            }
            &.product-line-grid-body {
            	@media (max-width: 767px) {
					width: 70%;
            	}
            }
            &.product-line-actions {
            	@media (max-width: 767px) {
					display: none;
            	}
            }
            .product-price {
            	display: block;
            	.product-discount {
            		float: left;
            	}
            }
            > div {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
  .cart-summary {
    .card-block {
      border-bottom: 1px solid $gray-lighter;
      &:last-child {
        border-bottom: none;
      }
      .cart-summary-line {
        line-height: 20px;
        .value {
          float: right;
          font-weight: 700;
        }
        &.cart-total {
          .label, .value {
            color: $textColor-1;
          }
        }
      }
    }
  }  
  .js-cart {
    margin-bottom: 0px;
  }
}